import React from 'react';
import { Datagrid, DateField, List, NumberField, ReferenceField, TextField } from 'react-admin';
import FieldStatusIncome from "./FieldStatusIncome";
function IncomeItemList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <ReferenceField label="Game" source="gameId"
                                reference="GameItem"
                                link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="month" />
                <TextField source="year" />
                <ReferenceField label="Khách" source="idCustomer"
                                reference="Customer"
                                link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <NumberField source="amount" />
                <FieldStatusIncome source="status"/>
                <TextField source="note" />
                <TextField source="description" />
                <DateField source="create_at" />
            </Datagrid>
        </List>
    );
}

export default IncomeItemList;
