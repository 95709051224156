import React from 'react';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CardWithIcon from './CardWithIcon';

function MonthlyRevenue(props) {
    const { value } = props;
    return (
        <CardWithIcon
            to="/commands"
            icon={PriceCheckIcon}
            title={'Doanh thu tháng này'}
            subtitle={value}
        />
    );
}

export default MonthlyRevenue;