import React from 'react';
import {
    CreateButton,
    Datagrid,
    DateField,
    FilterButton,
    List,
    NumberField,
    RecordContextProvider,
    ShowButton,
    TextField,
    TopToolbar,
    useListContext
} from 'react-admin';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery
} from "@mui/material";
import TrangThaiGameField from "../TrangThaiGameField";
import FieldTypeAccount from "./FieldTypeAccount";
import FieldOpenUrl from "./FieldOpenUrl";

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
    </TopToolbar>
);


function EmptyGameChannel() {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                Bạn chưa có game channel nào
            </Typography>
            <Typography variant="body1">
                Bạn hãy nhấn vào nút ở dưới để tạo mới.
            </Typography>
            <CreateButton
                variant="contained"
                sx={{mt: 2}}
                label="Thêm Kênh Game"
            />
        </Box>
    )
}
const postFilters = [
    ];

const GameChannelListMobile= () => {
    const {data} = useListContext();
    let idx = 1;
    return (
        <Box margin="0.5em">
            {
                data.map(record => (
                    <RecordContextProvider key={record.id} value={record}>
                        <Card
                            sx={{
                                margin: '0.5rem 0',
                            }}
                            variant="outlined"
                        >
                            <CardHeader
                                title={
                                    <>
                                        {idx++ + '. '}
                                        <TextField
                                            source="name"
                                            variant="body1"
                                        />
                                    </>
                                }
                                titleTypographyProps={{variant: 'body1'}}
                                action={<ShowButton/>}
                            />
                            <CardContent sx={{pt: 0}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label={'menu.about'} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Tên Kênh: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TextField source="name" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Số Game: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="soGame" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Email: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TextField source="email" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Trạng Thái: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TrangThaiGameField source="status" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Loai tài khoản: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <FieldTypeAccount source="type" label={"Loai tài khoản"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'CH Play: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <FieldOpenUrl/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </RecordContextProvider>
                ))
            }
        </Box>
    );
}

function GamechannelList(props) {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
        isXSmall ? (
            <List
                emptyWhileLoading
                {...props}
                filters={postFilters}
                actions={<ListActions/>}
                empty={<EmptyGameChannel/>}
            >
                <GameChannelListMobile/>
            </List>
        ) : (
            <>
                <List
                    {...props}
                    filters={postFilters}
                    actions={<ListActions/>}
                    empty={<EmptyGameChannel/>}
                >
                    <Datagrid rowClick="show">
                        <TextField source="name" label={"Tên Kênh"} />
                        <NumberField source="soGame" label={"Số Game"} />
                        <TextField source="email" />
                        <TrangThaiGameField source="status" label={"Trạng Thái"} />
                        <FieldTypeAccount source="type" label={"Loai tài khoản"} />
                        <DateField source="create_at" label={"Ngày Tạo"} />
                        <FieldOpenUrl/>
                    </Datagrid>
                </List>
            </>
        )
    );
}

export default GamechannelList;
