import React, {useEffect, useState} from 'react';
import {
    DateField,
    ImageField,
    NumberField,
    ReferenceField,
    Show,
    TextField,
    Title,
    useDataProvider,
    useNotify
} from 'react-admin';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery
} from "@mui/material";
import TrangThaiGameField from "../TrangThaiGameField";
import CHPlay from "../../Icon/CHPlay";
import {Link, useParams} from "react-router-dom";
import GameItemBatLuat from "./GameItemAction/GameItemBatLuat";
import GameItemDangBat from "./GameItemAction/GameItemDangBat";
import GameItemXemLuat from "./GameItemAction/GameItemXemLuat";
import GameItemSuaLuat from "./GameItemAction/GameItemSuaLuat";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CollectionsIcon from '@mui/icons-material/Collections';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShopIcon from '@mui/icons-material/Shop';
import {stringify} from "query-string";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import GameItemThemAnh from "./GameItemAction/GameItemThemAnh";

function GameitemShow(props) {
    const {id} = useParams();
    const dataProvider = useDataProvider();
    const [state, setState] = React.useState(0);
    const [infoGameItem, setInfoGameItem] = useState();
    const [images, setImages] = useState([]);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const notify = useNotify();
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState(0);
    };

    const changeState = (state) => {
        setState(state);
    }
    const callBackClose = () => {
        setState(0);
    }
    useEffect(() => {
        const refreshRecord = () => {
            dataProvider
                .getOne(`GameItem`, {
                    id: id,
                })
                .then((resources) => {
                    setInfoGameItem(resources.data);
                    //infoGameItem?.descriptionR
                    const imageArray = [resources.data.image1, resources.data.image2, resources.data.image3, resources.data.image4, resources.data.image5,].filter(Boolean); // loại bỏ các giá trị rỗng
                    setImages(imageArray);
                })
                .catch((error) => {
                    notify(`Lỗi: ${error.body}`, {type: 'error'});
                });
        };
        refreshRecord();
    }, [id, dataProvider, notify]);
    return (
        <Show>
            <Card>
                <Title
                    title={'pos.configuration'}
                >
                    <Typography>
                        {'Thông tin'}
                    </Typography>
                </Title>
                <CardContent>
                    {infoGameItem && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Button
                                variant="outlined"
                                href={infoGameItem?.status === 1 ? infoGameItem?.url : '#'}
                                startIcon={<ShopIcon/>}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{color: 'green'}}
                                sx={{flexBasis: 'calc(100% / 4 - 10px)'}}
                            >
                                CH Play
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<RemoveRedEyeIcon/>}
                                // target="_blank"
                                rel="noopener noreferrer"
                                sx={{color: 'green'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                sx={{flexBasis: 'calc(100% / 4 - 10px)'}}
                                component={Link}
                                to={{
                                    pathname: '/PlayerInfo',
                                    search: stringify({
                                        filter: JSON.stringify({_gameid: infoGameItem?.id}),
                                    }),
                                }}
                            >
                                Users
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<CollectionsIcon/>}
                                // target="_blank"
                                rel="noopener noreferrer"
                                sx={{color: 'green'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                sx={{flexBasis: 'calc(100% / 4 - 10px)'}}
                                component={Link}
                                to={{
                                    pathname: '/GameImages',
                                    search: stringify({
                                        filter: JSON.stringify({_gameid: infoGameItem?.id}),
                                    }),
                                }}
                            >
                                Images
                            </Button>
                        </Box>
                    )}
                    <Box display="flex">
                        <Box flex="1">
                            <TextField
                                source="name"
                                variant={"h5"}
                                component={"h2"}
                            />
                            <Box height={10}>&nbsp;</Box>
                            <TableContainer component={Paper}>
                                <Table aria-label={'menu.about'} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Id: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <NumberField source="id"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Kênh: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <ReferenceField label="Kênh" source="channel_id"
                                                                reference="gamechannel"
                                                                link="show">
                                                    <TextField source="name"/>
                                                </ReferenceField>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Trạng Thái: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TrangThaiGameField source="status" label="Trạng Thái"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Số Máy: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="playerCount" label={"Số Máy"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Đã Bật: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <NumberField source="deviceActive" label={"Đã Bật"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Id UI: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="idUI" label={"Id UI"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Code Active: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="codeCheckActive" label={"Code Active"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Ngày Tạo: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DateField source="create_at"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Note: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="description"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Ảnh Game: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                {/*<div style={{display: 'flex'}}>*/}
                                                {/*</div>*/}
                                                <ImageField
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setPhotoIndex(0);
                                                    }}
                                                    source="image1"/>
                                                <ImageField
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setPhotoIndex(1);
                                                    }}
                                                    source="image2"/>
                                                <ImageField
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setPhotoIndex(2);
                                                    }}
                                                    source="image3"/>
                                                <ImageField
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setPhotoIndex(3);
                                                    }}
                                                    source="image4"/>
                                                <ImageField
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setPhotoIndex(4);
                                                    }}
                                                    source="image5"/>
                                                {isOpen && (
                                                    <Lightbox
                                                        mainSrc={images[photoIndex]}
                                                        nextSrc={images[(photoIndex + 1) % images.length]}
                                                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                                        onCloseRequest={() => setIsOpen(false)}
                                                        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                                                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        sx={{marginTop: 2}}
                                        fullWidth
                                        onClick={() => changeState(1)}
                                    >
                                        {'Bật mã'}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        sx={{marginTop: 2}}
                                        fullWidth
                                        onClick={() => changeState(2)}
                                    >
                                        {'Đang bật'}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        size="small"
                                        sx={{marginTop: 2}}
                                        fullWidth
                                        onClick={() => changeState(3)}
                                    >
                                        {'Xem luật'}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        size="small"
                                        sx={{marginTop: 2}}
                                        fullWidth
                                        onClick={() => changeState(4)}
                                    >
                                        {'Sửa luật'}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        sx={{marginTop: 2}}
                                        fullWidth
                                        onClick={() => changeState(5)}
                                    >
                                        {'Thêm ảnh'}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    color="primary"*/}
                            {/*    size="small"*/}
                            {/*    sx={{*/}
                            {/*        marginTop: 2*/}
                            {/*    }}*/}
                            {/*    fullWidth*/}
                            {/*    onClick={() => changeState(5)}*/}
                            {/*>*/}
                            {/*    {'Các máy đang chơi'}*/}
                            {/*</Button>*/}
                            <Dialog
                                open={state !== 0}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullScreen={state === 2 && isXSmall || state === 4 && isXSmall}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {state === 1 && 'Bật mã'}
                                    {state === 2 && 'Đang bật'}
                                    {state === 3 && 'Xem luật'}
                                    {state === 4 && 'Sửa luật'}
                                    {state === 5 && 'Thêm ảnh'}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {state === 6 && 'Bạn có chắc chắn muốn thanh lý tài sản này để thu hồi tiền không?'}
                                        {state === 7 && 'Thanh toán các khoản tiền ở dưới để tất toán hợp đồng.'}
                                    </DialogContentText>
                                    {state === 1 && <GameItemBatLuat
                                        idgame={parseInt(id)}
                                        closeCallback={callBackClose}
                                    />}
                                    {state === 2 && <GameItemDangBat
                                        idgame={parseInt(id)}
                                    />}
                                    {state === 3 && <GameItemXemLuat idgame={parseInt(id)}/>}
                                    {state === 4 && <GameItemSuaLuat
                                        idgame={parseInt(id)}
                                        closeCallback={callBackClose}
                                    />}
                                    {state === 5 && <GameItemThemAnh
                                    idgame={parseInt(id)}
                                    closeCallback={callBackClose}
                                />}
                                    {/*{state === 5 && <ChuyenVaoNoXau*/}
                                    {/*    idhopdong={parseInt(id)}*/}
                                    {/*    closeCallback={callBackClose}*/}
                                    {/*/>}*/}
                                    {/*{state === 6 && <TatToanHopDong*/}
                                    {/*    idhopdong={parseInt(id)}*/}
                                    {/*    closeCallback={callBackClose}*/}
                                    {/*/>}*/}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Đóng</Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Show>
    );
}

export default GameitemShow;
