import React from 'react';
import {DateInput, Edit, NumberInput, FormRow, SimpleForm, TextInput, TextField, DateField} from 'react-admin';
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
function ExpenseItemEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <Box display="flex">
                    <Box flex="1">
                        <TableContainer component={Paper}>
                            <Table aria-label={'menu.about'} size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right" component="th" scope="row">
                                            <NumberInput source="gameId" />
                                        </TableCell>
                                        <TableCell align="left">
                                            <NumberInput source="amount" />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right" component="th" scope="row">
                                            <NumberInput source="month" />
                                        </TableCell>
                                        <TableCell align="left">
                                            <NumberInput source="year" />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>

                <TextInput multiline fullWidth source="note" />
                {/*<TextInput source="expenseType" />*/}
            </SimpleForm>
        </Edit>
    );
}
//tôi có một component react như trên tôi muốn 2 field input month, year hiển thị trên cùng một hàng
export default ExpenseItemEdit;
