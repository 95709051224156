import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card, Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { statusTypeRule } from '../../../utils/CommonHelper';
import { useDataProvider, useNotify } from 'react-admin';
import CHPlay from '../../../Icon/CHPlay';
import Sync from '../../../Icon/Sync';
import ShopIcon from "@mui/icons-material/Shop";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {Link} from "react-router-dom";
import {stringify} from "query-string";
import { green } from '@mui/material/colors';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {styled} from "@mui/material/styles";

function Loading() {
    return <p>Loading...</p>;
}
const GreenButton = styled(Button)({
    background: green[500],
    color: 'white',
    borderRadius: 20,
    padding: '12px 24px',
    '&:hover': {
        background: green[700],
    },
});
function GameItemXemLuat(props) {
    const { idgame } = props;
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [infoRule, setInfoRule] = useState();
    const handleCopyRuleClick = async () => {
        try {
            await navigator.clipboard.writeText(infoRule?.descriptionR);
            notify(`Đã Copy rule`, {type: 'success'})
        } catch (err) {
            console.error('Failed to copy: ', err);
            notify(`Lỗi: ${err}`, {type: 'error'})
        }
    };
    const handleCopyAvoidClick = async () => {
        try {
            await navigator.clipboard.writeText(infoRule?.descriptionA);
            notify(`Đã Copy avoid`, {type: 'success'})
        } catch (err) {
            console.error('Failed to copy: ', err);
            notify(`Lỗi: ${err}`, {type: 'error'})
        }
    };
    useEffect(() => {
        const refreshRecord = () => {
            setLoading(true);
            dataProvider
                .getOne(`GameItem/GetLuat`, {
                    id: idgame,
                })
                .then((resources) => {
                    setLoading(false);
                    setInfoRule(resources.data);
                })
                .catch((error) => {
                    setLoading(false);
                    notify(`Lỗi: ${error.body}`, { type: 'error' });
                });
        };
        refreshRecord();
    }, [idgame, dataProvider, notify]);


    return (
        <Card sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>
                Thông tin luật
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label={'menu.about'} size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Trạng Thái Luật Con: '}
                            </TableCell>
                            <TableCell align="left">
                                {loading ? <Loading /> : statusTypeRule(infoRule?.activeR)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Trạng Thái Luật Cái: '}
                            </TableCell>
                            <TableCell align="left">
                                {loading ? <Loading /> : statusTypeRule(infoRule?.activeA)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Luật Con: '}
                            </TableCell>
                            <TableCell align="left">
                                {loading ? <Loading /> : infoRule?.descriptionR}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Luật Cái: '}
                            </TableCell>
                            <TableCell align="left">
                                {loading ? <Loading /> : infoRule?.descriptionA}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2}
                    sx={{mt: 2}}
            >
                <Grid item xs={12} sm={6}>
                    <GreenButton
                        variant="contained"
                        startIcon={<ContentCopyIcon />}
                        onClick={() => handleCopyRuleClick()}
                    >
                        Copy Rule
                    </GreenButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <GreenButton
                        variant="contained"
                        startIcon={<ContentCopyIcon />}
                        onClick={() => handleCopyAvoidClick()}
                    >
                        Copy Avoid
                    </GreenButton>
                </Grid>
            </Grid>
        </Card>
    );
}

export default GameItemXemLuat;
