import React, {useState} from 'react';
import {Typography, useMediaQuery, Toolbar, Button} from "@mui/material";
import {
    DateField,
    List,
    ListContextProvider,
    NumberField,
    TextField, TopToolbar,
    useGetList,
    useList,
    useRedirect
} from "react-admin";
import GameItemDangBatMobile from "../../Game/GameItem/GameItemAction/GameItemDangBatMobile";
import FieldDeviceActiveStatus from "../../Game/DeviceActiveRule/FieldDeviceActiveStatus";
import HistoryExercisesMobile from "./HistoryExercisesMobile";


const ListActions = () => (
    <TopToolbar>
    </TopToolbar>
);
function HistoryInExercisesShow(props) {
    const {idexercise,target} = props;
    const perPage = 10;
    const [page, setPage] = useState(1);
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const redirect = useRedirect();
    const {data, total, isLoading, error} = useGetList(
        'Exercises/ListHistoryByDate',
        {
            pagination: {page: page, perPage: perPage},
            sort: {field: 'conLai', order: 'ASC'},
            filter: {target: target, idexercises: idexercise}
        }
    );
    const listContext = useList({data, isLoading});
    const handleClick = (id, event) => {
        redirect('/HistoryExercises/' + id + '/show');
    }
    if (isLoading) {
        return null;
    }
    if (error) {
        return null;
    }

    return (
        (data === null || data.length==0) ? (
            <Typography
                variant="h4"
                color="textSecondary"
                component="p"
                style={{ color: 'red' }}
            >
                Không có dữ liệu
            </Typography>
        ) : (
            <>
                <List
                    emptyWhileLoading
                    actions={<ListActions/>}
                >
                    <div>
                        <HistoryExercisesMobile
                            {...props}
                            data={data}
                            rowClick={handleClick}
                        />
                        <Toolbar>
                            {page > 1 && <Button onClick={() => setPage(page - 1)}>Previous page</Button>}
                            {page < total / perPage && <Button onClick={() => setPage(page + 1)}>Next page</Button>}
                        </Toolbar>
                    </div>

                </List>
            </>
        )
    );
}

export default HistoryInExercisesShow;
