export function formatNumber(amount) {
    if (amount) {
        return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return 0;
}

export function formatDate(strDate) {
    if (strDate) {
        let date = new Date(strDate);
        //return year, month, day, hour, minute, second
        // return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
}
export function statusTypeRule(status) {
    switch (status) {
        case 0:
            return 'Tắt';
        case 1:
            return 'Luật Theo Mã';
        case 2:
            return 'Luật Tất Cả';
        default:
            return 'không xác định';
    }
}

export function getDateTinhLai(strdate) {
    let curDate = new Date();
    if (strdate) {
        curDate = new Date(strdate);
    }
    return `${curDate.getFullYear()}-${curDate.getMonth() + 1}-${curDate.getDate()}`
}



export function getTrangThaiHD(status) {
    switch (status) {
        case 1:
            return 'Đang vay ';
        case 2:
            return 'Trễ hạn lãi';
        case 3:
            return 'Trễ hạn hợp đồng';
        case 4:
            return 'Đã trả hết';
        case 5:
            return 'Đã thanh lý';
        default:
            return 'Chưa xác định';
    }
}
export function getFormatTime(amout) {
    if (amout) {
        var hours = Math.floor(amout / 60);
        var minutes = amout % 60;
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (hours < 10) {
            hours = '0' + hours;
        }
        return hours + ':' + minutes;
    }
    return '00:00';
}
export function getTypeAccount(type) {
    switch (type) {
        case 1:
            return 'Phan Đăng Phú';
        case 2:
            return 'Đinh Hương';
        default:
            return 'Chưa xác định';
    }
}

export function getStatusTaiSan(status) {
    switch (status) {
        case 1:
            return 'Đang Thế chấp';
        case 4:
            return 'Đã trả hết';
        case 5:
            return 'Đã thanh lý';
        default:
            return 'Chưa xác định';
    }
}
export function getStatusTaiSanColor(status){
    switch (status) {
        case 1:
            return 'primary';
        case 2:
            return 'warning';
        case 3:
            return 'warning';
        case 4:
            return 'success';
        case 5:
            return 'error';
        default:
            return 'info';
    }
}

export function getTrangThaiHDColor(status) {
    switch (status) {
        case 1:
            return 'primary';
        case 2:
            return 'warning';
        case 3:
            return 'warning';
        case 4:
            return 'success';
        case 5:
            return 'error';
        default:
            return 'info';
    }
}
