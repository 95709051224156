import React from 'react';
import {
    BooleanField,
    DateField,
    ImageField,
    NumberField,
    Show,
    SimpleShowLayout,
    TextField,
    WrapperField
} from 'react-admin';
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
function HistoryexerciseShow(props) {
    return (
        <Show>
            <Card
                sx={{
                    margin: '0.5rem 0',
                    width: '100%'
                }}
                variant="outlined"
            >
                <CardHeader
                    title={
                        <Grid container alignItems="center">
                            <Grid item>
                                <WrapperField label="Ngày đo">
                                    <TextField style={{fontSize: '20px'}} source="day"/>-
                                    <TextField style={{fontSize: '20px'}} source="month"/>-
                                    <TextField style={{fontSize: '20px'}} source="year"/>
                                </WrapperField>
                            </Grid>
                        </Grid>
                    }
                    titleTypographyProps={{variant: 'body1'}}
                    action={
                        <></>
                    }
                />
                <CardContent sx={{pt: 0}}>
                    Tên set: <TextField source="name" />
                    <br/>
                    Kết quả: <TextField source="result" />
                    <br/>
                    Ghi chú: <TextField source="note" />
                    <br/>
                    Final name: <TextField source="finalName" />
                    <br/>
                </CardContent>
            </Card>
        </Show>
    );
}

export default HistoryexerciseShow;
