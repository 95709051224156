import * as React from 'react';
import {Button} from "@mui/material";
import { Link } from 'react-router-dom';
import {useRecordContext } from 'react-admin';
import { stringify } from 'query-string';
// import CHPlay from "../../Icon/CHPlay";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const LinkToRelatedProducts = () => {
    const record =useRecordContext();
    if (!record) {
        return null;
    }
    return (
        <Button
            variant="outlined"
            startIcon={<RemoveRedEyeIcon/>}
            // target="_blank"
            rel="noopener noreferrer"
            sx={{color: 'green'}}
            onClick={ (e) => { e.stopPropagation(); } }

            component={Link}
            to={{
                pathname: '/PlayerInfo',
                search: stringify({
                    filter: JSON.stringify({ _gameid: record.id }),
                }),
            }}
        >
            Users
        </Button>
    );
};

export default LinkToRelatedProducts;
