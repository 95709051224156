import React from 'react';
import {DateField, NumberField, Show, TextField, Title} from 'react-admin';
import {
    Box,
    Button,
    Card,
    CardContent,
    Drawer,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import TrangThaiGameField from "../TrangThaiGameField";
import FieldTypeAccount from "./FieldTypeAccount";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import GameItemCreateForm from "../GameItem/GameItemCreateForm";

function GamechannelShow(props) {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    return (
        <Show>
            <Card>
                <Title
                    title={'pos.configuration'}
                >
                    <Typography>
                        {'Thông tin'}
                    </Typography>
                </Title>
                <CardContent>
                    <Box display="flex">
                        <Box flex="1">
                            <TextField
                                source="name"
                                variant={"h5"}
                                component={"h2"}
                            />
                            <Box height={10}>&nbsp;</Box>
                            <TableContainer component={Paper}>
                                <Table aria-label={'menu.about'} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Số Game: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <NumberField source="soGame" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Email: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="email"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Trạng Thái: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TrangThaiGameField source="status"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Loại Tài Khoản: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <FieldTypeAccount source="type" label={"Loai tài khoản"} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Ngày Tạo: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DateField source="create_at" />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    sx={{marginTop: 2}}
                                    onClick={handleToggle}
                                >
                                    {'Tạo Game'}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <Drawer
                open={open}
                onClose={handleClose}
            >
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        // width: '100%',
                    }}
                    open={open}
                >
                    <CardContent>
                        <Box display="flex">
                            <Box flex="1">

                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" gutterBottom>
                                            {'Nhập thông tin game'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            aria-label="close"
                                            onClick={handleClose}
                                            color={"error"}
                                            size="large"
                                        >
                                            <HighlightOffIcon fontSize="inherit"/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <GameItemCreateForm/>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Drawer>
        </Show>
    );
}

export default GamechannelShow;
