import React from 'react';
import {BooleanInput, DateInput, Edit, NumberInput, SimpleForm, TextInput} from 'react-admin';
import {Grid} from "@mui/material";
function WeightgymEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <NumberInput fullWidth source="day" label={"Ngày"} />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput fullWidth source="month" label={"Tháng"} />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput fullWidth source="year" label={"Năm"} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <NumberInput fullWidth source="weight" label={"Cân nặng"} />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput fullWidth source="waist" label={"Vòng eo"} />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput fullWidth source="height" label={"Chiều cao"} />
                    </Grid>
                </Grid>
                <BooleanInput label={"Có Coach"} source="isCoach" />
                <TextInput fullWidth source="note" />
                <TextInput fullWidth source="image1" />
                <TextInput fullWidth source="image2" />
                <TextInput fullWidth source="image3" />
            </SimpleForm>
        </Edit>
    );
}

export default WeightgymEdit;
