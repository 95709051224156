import React from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader, Grid,
    IconButton,
    Paper, Table, TableBody, TableCell,
    TableContainer, TableRow,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    DateField,
    List,
    NumberField,
    RecordContextProvider, ReferenceField,
    TextField,
    TopToolbar,
    useGetList,
    useList,
    useRedirect, WrapperField
} from "react-admin";

const ListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

function HistoryExercisesMobile(props) {
    const {data, rowClick} = props;
    // let idx = 1;
    return (
        <Box
            sx={{width: '100%'}}
        >
            {
                data.map(record => (
                    <RecordContextProvider key={record.id} value={record}>
                        <Card
                            sx={{
                                margin: '0.5rem 0',
                                width: '100%'
                            }}
                            variant="outlined"
                        >
                            <CardHeader
                                title={
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            {/*<Typography style={{ fontSize: '20px' }}>*/}
                                            {/*    {idx++ + '. '}*/}
                                            {/*</Typography>*/}
                                        </Grid>
                                        <Grid item>
                                            <WrapperField label="Ngày tập">
                                                <TextField style={{ fontSize: '20px' }} source="day"/>-
                                                <TextField style={{ fontSize: '20px' }} source="month"/>-
                                                <TextField style={{ fontSize: '20px' }} source="year"/>
                                            </WrapperField>
                                        </Grid>
                                    </Grid>
                                    // <>
                                    //     <Typography style={{ fontSize: '20px' }} >
                                    //         {idx++ + '. '}
                                    //     </Typography>
                                    //     <TextField style={{ fontSize: '20px' }} source="day"/>
                                    //     <WrapperField label="Ngày tập">
                                    //         <TextField style={{ fontSize: '20px' }} source="day"/>/
                                    //         <TextField style={{ fontSize: '20px' }} source="month"/>/
                                    //         <TextField style={{ fontSize: '20px' }} source="year"/>
                                    //     </WrapperField>
                                    // </>
                                }
                                titleTypographyProps={{variant: 'body1'}}
                                action={
                                <></>
                                    // <IconButton
                                    //     aria-label="show"
                                    //     onClick={() => {
                                    //         rowClick(record.id);
                                    //     }}
                                    //     color={'primary'}
                                    // >
                                    //     <VisibilityIcon/>
                                    // </IconButton>
                                }
                            />
                            <CardContent sx={{pt: 0}}>
                                {record.historyExercises.map(exercise => (
                                    <RecordContextProvider key={exercise.id} value={exercise}>
                                        <>
                                            <ReferenceField label="Bài tập" source="idx"
                                                            reference="HistoryExercises"
                                                            link="edit">
                                                <TextField source="finalName"/>
                                            </ReferenceField>
                                            {/*{idx++ + '. '}*/}
                                            {/*<WrapperField label="Ngày tập">*/}
                                            {/*    <TextField  source="name"/>:*/}
                                            {/*    <TextField  source="result"/>*/}
                                            {/*    (<TextField  source="note"/>)*/}
                                            {/*</WrapperField>*/}

                                            <Box mb={1}/>
                                        </>
                                        {/*<Card*/}
                                        {/*    sx={{*/}
                                        {/*        margin: '0.5rem 0',*/}
                                        {/*        width: '100%'*/}
                                        {/*    }}*/}
                                        {/*    variant="outlined"*/}
                                        {/*>*/}
                                        {/*    <CardHeader*/}
                                        {/*        title={*/}
                                        {/*            <>*/}
                                        {/*                /!*{idx++ + '. '}*!/*/}
                                        {/*                <WrapperField label="Ngày tập">*/}
                                        {/*                    <TextField  source="name"/>:*/}
                                        {/*                    <TextField  source="result"/>*/}
                                        {/*                    (<TextField  source="note"/>)*/}
                                        {/*                </WrapperField>*/}
                                        {/*            </>*/}
                                        {/*        }*/}
                                        {/*    />*/}
                                        {/*</Card>*/}
                                    </RecordContextProvider>
                                ))}
                                {/*<Grid container spacing={2}>*/}
                                {/*    <Grid item xs={6}>*/}
                                {/*        <TextField source="name"/>*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={6}>*/}
                                {/*        <TextField source="result"/>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}
                                {/*<TableContainer component={Paper}>*/}
                                {/*    <Table aria-label={'menu.about'} size="small">*/}
                                {/*        <TableBody>*/}
                                {/*            <TableRow>*/}
                                {/*                <TableCell align="right" component="th" scope="row">*/}
                                {/*                    {'name set: '}*/}
                                {/*                </TableCell>*/}
                                {/*                <TableCell align="left">*/}
                                {/*                    <TextField source="name"/>*/}
                                {/*                </TableCell>*/}
                                {/*            </TableRow>*/}
                                {/*            <TableRow>*/}
                                {/*                <TableCell align="right" component="th" scope="row">*/}
                                {/*                    {'Result: '}*/}
                                {/*                </TableCell>*/}
                                {/*                <TableCell align="left">*/}
                                {/*                    <TextField source="result"/>*/}
                                {/*                </TableCell>*/}
                                {/*            </TableRow>*/}
                                {/*            <TableRow>*/}
                                {/*                <TableCell align="right" component="th" scope="row">*/}
                                {/*                    {'Note: '}*/}
                                {/*                </TableCell>*/}
                                {/*                <TableCell align="left">*/}
                                {/*                    <TextField source="note"/>*/}
                                {/*                </TableCell>*/}
                                {/*            </TableRow>*/}
                                {/*        </TableBody>*/}
                                {/*    </Table>*/}
                                {/*</TableContainer>*/}
                            </CardContent>
                        </Card>
                    </RecordContextProvider>
                ))
            }
        </Box>
        // <Card>
        //     <CardContent>
        //
        //     </CardContent>
        // </Card>
    );
}

export default HistoryExercisesMobile;
