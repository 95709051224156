import React, {useEffect, useState} from 'react';
import {DateField, NumberField, ReferenceField, Show, TextField, Title, useDataProvider, useNotify} from 'react-admin';
import {
    Box, Button,
    Card,
    CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography, useMediaQuery
} from "@mui/material";
import TimeCachDayField from "./TimeCachDayField";
import {useParams} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {green} from "@mui/material/colors";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ActionBatLuat from "./Action/ActionBatLuat";

const GreenButton = styled(Button)({
    background: green[500],
    color: 'white',
    borderRadius: 20,
    padding: '12px 24px',
    '&:hover': {
        background: green[700],
    },
});
function PlayerinfoShow(props) {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [state, setState] = React.useState(0);
    const [playerInfo, setPlayerInfo] = useState();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState(0);
    };
    const changeState = (state) => {
        setState(state);
    }
    const callBackClose = () => {
        setState(0);
    }
    const handleCopyIdClick = async () => {
        try {
            await navigator.clipboard.writeText(playerInfo?.deviceId);
            notify(`Đã Copy Device Id`, {type: 'success'})
        } catch (err) {
            console.error('Failed to copy: ', err);
            notify(`Lỗi: ${err}`, {type: 'error'})
        }
    };
    useEffect(() => {
        const refreshRecord = () => {
            setLoading(true);
            dataProvider
                .getOne(`PlayerInfo`, {
                    id: id,
                })
                .then((resources) => {
                    setLoading(false);
                    setPlayerInfo(resources.data);
                })
                .catch((error) => {
                    setLoading(false);
                    notify(`Lỗi: ${error.body}`, { type: 'error' });
                });
        };
        refreshRecord();
    }, [id, dataProvider, notify]);
    return (
        <Show>
            {!loading && (
                <Card>
                    <Title
                        title={'Chi tiết'}
                    >
                        <Typography>
                            {'Thông tin'}
                        </Typography>
                    </Title>
                    <CardContent>
                        <Box display="flex">
                            <Box flex="1">
                                <TextField
                                    source="name"
                                    variant={"h5"}
                                    component={"h2"}
                                />
                                <Box height={10}>&nbsp;</Box>
                                <TableContainer component={Paper}>
                                    <Table aria-label={'menu.about'} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Id: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="id" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Game: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <ReferenceField label="Game" source="gameId"
                                                                    reference="GameItem"
                                                                    link="show">
                                                        <TextField source="name"/>
                                                    </ReferenceField>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Mã máy: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField source="deviceId" label={"Mã máy"}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Device Unique Id: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField source="deviceUniqueId" label={"Device Unique Id"}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Device Name: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField source="deviceName" label={"Device Name"}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Device Model: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField source="deviceModel" label={"Device Model"}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Số Ván: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <NumberField source="soVan" label={"Số Ván"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Đã dùng: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <NumberField source="timeUsed" label={"Đã dùng"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Cách đây: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TimeCachDayField source="timeCachDay" label={"Cách đây"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Lần chơi cuối: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <DateField source="lastUpdate" showTime label={"Lần chơi cuối"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Bắt đầu chơi: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <DateField source="create_at" showTime label={"Bắt đầu chơi"} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </CardContent>
                    <GreenButton
                        variant="contained"
                        startIcon={<ContentCopyIcon />}
                        onClick={() => handleCopyIdClick()}
                    >
                        Copy Device Id
                    </GreenButton>
                    <GreenButton
                        variant="contained"
                        startIcon={<LockOpenIcon />}
                        onClick={() => changeState(1)}
                    >
                        Bật Luật
                    </GreenButton>
                    <Dialog
                        open={state !== 0}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullScreen={state === 2 && isXSmall || state === 4 && isXSmall}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {state === 1 && 'Bật Luật'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            </DialogContentText>
                            {state === 1 && <ActionBatLuat
                                iditem={parseInt(id)}
                                closeCallback={callBackClose}
                            />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Đóng</Button>
                        </DialogActions>
                    </Dialog>
                </Card>
            )}
        </Show>
    );
}

export default PlayerinfoShow;
