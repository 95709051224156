import React from 'react';
import {Datagrid, DateField, List, NumberField, ReferenceField, TextField, WrapperField} from 'react-admin';

function HistoryexerciseList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id"/>
                <ReferenceField label="Bài tập" source="idExercises"
                                reference="Exercises"
                                link="show">
                    <TextField source="nameEL"/>
                </ReferenceField>
                <WrapperField label="Ngày tập">
                    <TextField source="day"/>/
                    <TextField source="month"/>/
                    <TextField source="year"/>
                </WrapperField>
                {/*    <TextField source="day"/>/*/}
                {/*    <TextField source="month"/>/*/}
                {/*    <TextField source="year"/>*/}
                {/*</>*/}
                <TextField source="name"/>
                <TextField source="result"/>
                <TextField source="note"/>
            </Datagrid>
        </List>
    );
}

export default HistoryexerciseList;
