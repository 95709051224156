import React from 'react';
import {Edit, NumberInput, SimpleForm} from 'react-admin';

function DeviceactiveruleEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <NumberInput source="minuteUse" />
            </SimpleForm>
        </Edit>
    );
}

export default DeviceactiveruleEdit;
