import React from 'react';
import {
    AutocompleteArrayInput,
    BooleanInput,
    SimpleForm,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRefresh
} from "react-admin";
import {Button} from "@mui/material";

function UserEditKhoaAccountToolbar() {
    return null;
}

function UserEditKhoaAccount(props) {
    const {closeCallback} = props;
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const record = useRecordContext();
    const notify = useNotify();
    const profile = (dataPut) => {
        const diff = {
            roles: dataPut.roles,
            active: dataPut.active === true ? 1 : 0,
            idIdenUser: record.id,
        };
        dataProvider
            .update('user/admin/active', {
                id: record.userInfo.id,
                data: diff,
            })
            .then(() => {
                closeCallback();
                refresh();
                notify('Cập nhật thành công',{ type: 'success' });
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    };
    if (!record) return null;
    const roles = [
        {id: 'admin', name: 'admin'},
        {id: 'creator', name: 'creator'},
        {id: 'member', name: 'member'},
    ];
    return (
        <SimpleForm
            onSubmit={profile}
            toolbar={<UserEditKhoaAccountToolbar/>}
        >
            <AutocompleteArrayInput
                source="roles"
                choices={roles}
                label="Roles"
                defaultValue={record.userInfo.roles}
                fullWidth
            />
            <BooleanInput
                source="active"
                label="Kích hoạt"
                fullWidth
                defaultValue={record.userInfo.active === 1 ? true : false}
            />


            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                Cập nhật
            </Button>
        </SimpleForm>
    );
}

export default UserEditKhoaAccount;