import React from 'react';
import { DateInput, Edit, SimpleForm, TextInput } from 'react-admin';
function CustomerEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="phone" />
                <TextInput fullWidth multiline source="note" />
            </SimpleForm>
        </Edit>
    );
}

export default CustomerEdit;
