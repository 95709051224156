import React, {useCallback, useEffect, useState} from 'react';
import {
    AutocompleteInput,
    NumberInput,
    SimpleForm,
    useDataProvider,
    useNotify,
    useRefresh,
    TextField
} from "react-admin";
import {Button, Grid} from "@mui/material";
import Sync from "../../../Icon/Sync";

function GameItemSuaLuatToolbar() {
    return null;
}

function validateRuleUpdate(values) {
    const errors = {};
    if (values.activeA === undefined || values.activeA === null || values.activeA === '') {
        errors.activeA = 'Luật cái không được để trống';
    }
    if (values.activeR === undefined || values.activeR === null || values.activeR === '') {
        errors.activeR = 'Luật con không được để trống';
    }
    if (values.activeR === 1 || values.activeR === 2) {
        if (values.nai === undefined || values.nai === null || values.nai === '') {
            errors.nai = 'Nai không được để trống';
        }
        if (values.bau === undefined || values.bau === null || values.bau === '') {
            errors.bau = 'Bầu không được để trống';
        }
        if (values.ga === undefined || values.ga === null || values.ga === '') {
            errors.ga = 'Gà không được để trống';
        }
        if (values.ca === undefined || values.ca === null || values.ca === '') {
            errors.ca = 'Cá không được để trống';
        }
        if (values.cua === undefined || values.cua === null || values.cua === '') {
            errors.cua = 'Cua không được để trống';
        }
        if (values.tom === undefined || values.tom === null || values.tom === '') {
            errors.tom = 'Tôm không được để trống';
        }
        if (values.n === undefined || values.n === null || values.n === '') {
            errors.n = 'N không được để trống';
        }
        if (values.con === undefined || values.con === null || values.con === '') {
            errors.con = 'con Random không được để trống';
        }
        if (values.typeR === undefined || values.typeR === null || values.typeR === '') {
            errors.typeR = 'Luật không được để trống';
        }
        let list = [values.nai, values.bau, values.ga, values.ca, values.cua, values.tom];
        let mapList = [
            list.filter((item) => item === 0).length,
            list.filter((item) => item === 1).length,
            list.filter((item) => item === 2).length,
            list.filter((item) => item === 3).length,
            list.filter((item) => item === 4).length,
            list.filter((item) => item === 5).length,
        ]
        let max = Math.max(...mapList);
        if (max > 1) {
            errors.nai = 'Giá trị không được trùng nhau';
            errors.bau = 'Giá trị không được trùng nhau';
            errors.ga = 'Giá trị không được trùng nhau';
            errors.ca = 'Giá trị không được trùng nhau';
            errors.cua = 'Giá trị không được trùng nhau';
            errors.tom = 'Giá trị không được trùng nhau';
        }
        if (values.n > 5 || values.n < 0) {
            errors.n = 'Giá trị phải từ 0 đến 5';
        }
    }
    if (values.activeA != 0) {
        if (values.cai1 === undefined || values.cai1 === null || values.cai1 === '') {
            errors.cai1 = 'Hãm 1 không được để trống';
        }
        if (values.cai2 === undefined || values.cai2 === null || values.cai2 === '') {
            errors.cai2 = 'Hãm 2 không được để trống';
        }
        if (values.cai3 === undefined || values.cai3 === null || values.cai3 === '') {
            errors.cai3 = 'Hãm 3 không được để trống';
        }
        if (values.cai4 === undefined || values.cai4 === null || values.cai4 === '') {
            errors.cai4 = 'Hãm 4 không được để trống';
        }
        if (values.cai5 === undefined || values.cai5 === null || values.cai5 === '') {
            errors.cai5 = 'Hãm 5 không được để trống';
        }
        if (values.cai6 === undefined || values.cai6 === null || values.cai6 === '') {
            errors.cai6 = 'Hãm 6 không được để trống';
        }
        let listHam = [values.cai1, values.cai2, values.cai3, values.cai4, values.cai5, values.cai6];
        let mapListHam = [
            listHam.filter((item) => item === 0).length,
            listHam.filter((item) => item === 1).length,
            listHam.filter((item) => item === 2).length,
            listHam.filter((item) => item === 3).length,
            listHam.filter((item) => item === 4).length,
            listHam.filter((item) => item === 5).length,
        ]
        let maxHam = Math.max(...mapListHam);
        if (maxHam > 1) {
            errors.cai1 = 'Giá trị không được trùng nhau';
            errors.cai2 = 'Giá trị không được trùng nhau';
            errors.cai3 = 'Giá trị không được trùng nhau';
            errors.cai4 = 'Giá trị không được trùng nhau';
            errors.cai5 = 'Giá trị không được trùng nhau';
            errors.cai6 = 'Giá trị không được trùng nhau';
        }
    }
    return errors;
}

function GameItemSuaLuat(props) {
    const {idgame, closeCallback} = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [infoRule, setInfoRule] = useState();
    const [ruleStatus, setRuleStatus] = React.useState({
        con: 0,
        cai: 0,
    });

    useEffect(() => {
        const refreshRecord = () => {
            dataProvider.getOne(`GameItem/GetLuat`, {
                id: idgame
            })
                .then((resources) => {
                    setInfoRule(resources.data);
                    setRuleStatus({
                        con: resources.data.activeR,
                        cai: resources.data.activeA,
                    });
                })
                .catch((error) => {
                    notify(`Lỗi: ${error.body}`, {type: 'error'});
                });
        };
        refreshRecord();
    }, [idgame, dataProvider, notify]);
    const setRuleCon = (value) => {
        setRuleStatus({
            ...ruleStatus,
            con: value
        })
    }
    const setRuleCai = (value) => {
        setRuleStatus({
            ...ruleStatus,
            cai: value
        })
    }
    const choiseLuat = [
        {id: 0, name: '1, B'},
        {id: 1, name: '2, B + C'},
        {id: 2, name: '3, A + B + C'},
        {id: 3, name: '4, A + C'},
        {id: 4, name: '5, A + B'},
        {id: 5, name: '6, A * 2 + B'},
        {id: 6, name: '7, B * 2 + C'}
    ];
    const choiseCon = [
        {id: 0, name: 'Nai'},
        {id: 1, name: 'Bầu'},
        {id: 2, name: 'Gà'},
        {id: 3, name: 'Cá'},
        {id: 4, name: 'Cua'},
        {id: 5, name: 'Tôm'}
    ];
    const choiseConRandom = [...choiseCon, {id: 6, name: 'Không dùng'}];
    const choiseActive = [
        {id: 0, name: 'Tắt'},
        {id: 1, name: 'Bật theo mã'},
        {id: 2, name: 'Bật tất cả'}
    ];
    const putSuaLuat = (data) => {
        dataProvider
            .update('GameItem/UpdateRule', {
                id: idgame,
                data: {
                    activeR: data.activeR,
                    activeA: data.activeA,

                    nai: data.nai,
                    bau: data.bau,
                    ga: data.ga,
                    ca: data.ca,
                    cua: data.cua,
                    tom: data.tom,

                    cai1: data.cai1,
                    cai2: data.cai2,
                    cai3: data.cai3,
                    cai4: data.cai4,
                    cai5: data.cai5,
                    cai6: data.cai6,

                    con: data.con,
                    n: data.n,
                    typeR: data.typeR,
                    typeA: 0
                },
            })
            .then(() => {
                closeCallback();
                refresh();
                notify(`Sửa luật thành công`, {type: 'success'});
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    };
    return (
        <SimpleForm
            onSubmit={putSuaLuat}
            toolbar={<GameItemSuaLuatToolbar/>}
            validate={validateRuleUpdate}
        >
            {
                infoRule && (
                    <AutocompleteInput
                        source="activeR"
                        label="Luật con"
                        choices={choiseActive}
                        defaultValue={infoRule?.activeR}
                        onChange={(e) => {
                            if (e) {
                                setRuleCon(e);
                            } else {
                                setRuleCon(0);
                            }
                        }}
                        fullWidth
                    />
                )}
            {ruleStatus.con !== 0 && (
                infoRule && (
                    <AutocompleteInput
                        source="typeR"
                        label="Chọn luật"
                        defaultValue={infoRule?.typeR}
                        choices={choiseLuat}
                        fullWidth
                    />
                )
            )}
            {ruleStatus.con !== 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="con"
                                    label="Con Random"
                                    defaultValue={infoRule?.con}
                                    choices={choiseConRandom}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <NumberInput
                                    source="n"
                                    label="N"
                                    defaultValue={infoRule?.n}
                                    fullWidth
                                />
                            )
                        }
                    </Grid>
                </Grid>
            )}
            {ruleStatus.con !== 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="nai"
                                    label="Nai"
                                    defaultValue={infoRule?.nai}
                                    choices={choiseCon}
                                    fullWidth
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="bau"
                                    label="Bầu"
                                    defaultValue={infoRule?.bau}
                                    choices={choiseCon}
                                    fullWidth
                                />
                            )
                        }
                    </Grid>
                </Grid>
            )}
            {ruleStatus.con !== 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="ga"
                                    label="Gà"
                                    defaultValue={infoRule?.ga}
                                    choices={choiseCon}
                                    fullWidth
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="ca"
                                    label="Cá"
                                    defaultValue={infoRule?.ca}
                                    choices={choiseCon}
                                    fullWidth
                                />
                            )
                        }
                    </Grid>
                </Grid>
            )}
            {ruleStatus.con !== 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="cua"
                                    label="Cua"
                                    defaultValue={infoRule?.cua}
                                    choices={choiseCon}
                                    fullWidth
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="tom"
                                    label="Tôm"
                                    defaultValue={infoRule?.tom}
                                    choices={choiseCon}
                                    fullWidth
                                />
                            )
                        }
                    </Grid>
                </Grid>
            )}
            {
                infoRule && (
                    <AutocompleteInput
                        source="activeA"
                        label="Luật cái"
                        choices={choiseActive}
                        defaultValue={infoRule?.activeA}
                        onChange={(e) => {
                            if (e) {
                                setRuleCai(e);
                            } else {
                                setRuleCai(0);
                            }
                        }}
                        fullWidth
                    />
                )
            }
            {ruleStatus.cai !== 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="cai1"
                                    label="Hãm 1"
                                    defaultValue={infoRule?.cai1}
                                    choices={choiseCon}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="cai2"
                                    label="Hãm 2"
                                    defaultValue={infoRule?.cai2}
                                    choices={choiseCon}
                                />
                            )
                        }

                    </Grid>
                </Grid>
            )}
            {ruleStatus.cai !== 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="cai3"
                                    label="Hãm 3"
                                    defaultValue={infoRule?.cai3}
                                    choices={choiseCon}
                                />
                            )
                        }

                    </Grid>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="cai4"
                                    label="Hãm 4"
                                    defaultValue={infoRule?.cai4}
                                    choices={choiseCon}
                                />
                            )
                        }

                    </Grid>
                </Grid>
            )}
            {ruleStatus.cai !== 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="cai5"
                                    label="Hãm 5"
                                    defaultValue={infoRule?.cai5}
                                    choices={choiseCon}
                                />
                            )
                        }

                    </Grid>
                    <Grid item xs={6}>
                        {
                            infoRule && (
                                <AutocompleteInput
                                    source="cai6"
                                    label="Hãm 6"
                                    defaultValue={infoRule?.cai6}
                                    choices={choiseCon}
                                />
                            )
                        }

                    </Grid>
                </Grid>
            )}
            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                sx={{mt: 3, mb: 2}}
            >
                Đồng ý
            </Button>
        </SimpleForm>
    );
}

export default GameItemSuaLuat;
