import React, {useEffect, useState} from 'react';
import './Calendar.css';
import {DeleteButton, FormTab, SaveButton, TabbedForm, Toolbar, useDataProvider, useNotify} from "react-admin";
import {Typography} from "@mui/material";
import GeneralResult from "./Exercises/GeneralGym/GeneralResult";
import GeneralInfo from "./Exercises/GeneralGym/GeneralInfo";

const Calendar = () => {
    const [loading, setLoading] = useState(false);
    const daysOfWeek = ['Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7', 'CN'];
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [infoCalendar, setInfoCalendar] = useState();
    const PostEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton disabled/>
            <DeleteButton disabled/>
        </Toolbar>
    );
    useEffect(() => {
        const refreshRecord = () => {
            setLoading(true);
            dataProvider.getOne(`WeightGym/GetCalendar`, {
                id: 0
            })
                .then((resources) => {
                    setLoading(false);
                    setInfoCalendar(resources.data);
                })
                .catch((error) => {
                    setLoading(false);
                    notify(`Lỗi: ${error.body}`, {type: 'error'});
                });
        };
        refreshRecord();
    }, [dataProvider, notify]);

    const renderCalendar = (month) => {
        // const { name, days, startDay } = month;
        const daysOfMonth = Array.from({ length: infoCalendar?.days }, (_, i) => i + 1);
        return (
            <>
                <div className="month">{infoCalendar?.name}</div>
                <table className="calendar-table">
                    <thead>
                    <tr className="title-row">
                        {daysOfWeek.map((day, index) => (
                            <th key={index} className="day">{day}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {Array.from({ length: Math.ceil((infoCalendar?.days + infoCalendar?.startDay - 1) / 7) }, (_, rowIndex) => (
                        <tr key={rowIndex} className="date-row">
                            {Array.from({ length: 7 }, (_, colIndex) => {
                                const dayIndex = rowIndex * 7 + colIndex + 1 - infoCalendar?.startDay;
                                const day = daysOfMonth[dayIndex];

                                if (day) {
                                    return (
                                        <td key={colIndex} className={`date-cell ${infoCalendar?.coach.includes(day) ? 'red-dot' : ''} ${infoCalendar?.myExer.includes(day) ? 'green-dot' : ''}`}>
                                            <span className="day-number">{day}</span>
                                        </td>
                                    );
                                }

                                return <td key={colIndex} className="date-cell"></td>;
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
                <TabbedForm toolbar={<PostEditToolbar/>}>
                    <FormTab label="Info">
                        {/*<Typography variant="h6" gutterBottom component="div">*/}
                        {/*    Thông tin chung*/}
                        {/*</Typography>*/}
                        <GeneralInfo/>
                        {/*<GeneralResult  istarget={0}/>*/}
                    </FormTab>
                    <FormTab label="Result">
                        <Typography variant="h6" gutterBottom component="div">
                            Result
                        </Typography>
                        <GeneralResult  istarget={0}/>
                    </FormTab>
                    <FormTab label="Target">
                        <Typography variant="h6" gutterBottom component="div">
                            Target
                        </Typography>
                        <GeneralResult  istarget={1}/>
                    </FormTab>
                </TabbedForm>
            </>
        );
    };

    return (

        <div className="calendar">
            {loading ? <p>Loading...</p> :
                (<React.Fragment key={infoCalendar?.id}>{renderCalendar(infoCalendar?.month)}</React.Fragment>)
            }

            {/*{months.map((month, index) => (*/}
            {/*    */}
            {/*))}*/}
        </div>
    );
};

export default Calendar;
