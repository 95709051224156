import React from 'react';
import CardWithIcon from "./CardWithIcon";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

function TotalMoney(props) {
    const { value } = props;
    return (
        <CardWithIcon
            to="/commands"
            icon={AccountBalanceWalletIcon}
            title={'Tổng doanh thu'}
            subtitle={value}
        />
    );
}

export default TotalMoney;
