import React from 'react';
import { Datagrid, DateField, List, NumberField, ReferenceField, TextField } from 'react-admin';
function ExpenseItemList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <ReferenceField label="Game" source="gameId"
                                reference="GameItem"
                                link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="month" />
                <NumberField label={"Số tiền"} source="amount" />
                <TextField source="note" />
                {/*<TextField source="expenseType" />*/}
                <DateField source="create_at" />
            </Datagrid>
        </List>
    );
}

export default ExpenseItemList;
