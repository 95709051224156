import React from 'react';
import {SvgIcon} from "@mui/material";

function Sync(props) {

    return (
        <SvgIcon
            {...props}
            viewBox="0 0 48 48"
            width="48px"
            height="48px"
        >
            <polygon fill="#3ddab4" points="17.404,41.919 15.059,28.421 3.906,39.574"/>
            <path fill="#3ddab4" d="M9.928,9.905C6.173,13.659,4.106,18.65,4.106,23.96c0,5.309,2.067,10.3,5.822,14.054 c0.01,0.01,0.02,0.018,0.03,0.028l2.829-2.829c-0.01-0.01-0.021-0.018-0.031-0.028c-2.999-2.998-4.65-6.985-4.65-11.226 c0-4.241,1.651-8.228,4.65-11.227c3.095-3.095,7.161-4.642,11.226-4.642c0.901,0,1.801,0.089,2.691,0.241l1.092-3.879 C21.502,3.249,14.767,5.065,9.928,9.905z"/>
            <path fill="#3ddab4" d="M38.963,10.927l-2.828,2.828c2.406,2.856,3.723,6.427,3.723,10.205c0,4.24-1.651,8.228-4.65,11.226 c-2.999,2.999-6.985,4.65-11.226,4.65c-0.599,0-1.191-0.042-1.779-0.107l-1.092,3.878c0.944,0.136,1.899,0.229,2.87,0.229 c5.309,0,10.3-2.067,14.054-5.822c3.754-3.754,5.822-8.745,5.822-14.054C43.858,19.114,42.119,14.544,38.963,10.927z"/>
            <polygon fill="#3ddab4" points="31.303,6.745 33.648,20.242 44.801,9.089"/>
            <path fill="#00b569" d="M38.963,10.927l-2.828,2.828c0.623,0.74,1.17,1.53,1.643,2.357l2.892-2.892 C40.154,12.424,39.595,11.652,38.963,10.927z"/>
            <path fill="#00b569" d="M9.928,9.905C6.173,13.659,4.106,18.65,4.106,23.96c0,5.309,2.067,10.3,5.822,14.054 c0.01,0.01,0.02,0.018,0.03,0.028l2.829-2.829c-0.01-0.01-0.021-0.018-0.031-0.028c-2.999-2.998-4.65-6.985-4.65-11.226 c0-4.241,1.651-8.228,4.65-11.227c3.095-3.095,7.161-4.642,11.226-4.642c0.901,0,1.801,0.089,2.691,0.241l1.092-3.879 C21.502,3.249,14.767,5.065,9.928,9.905z"/>
            <path fill="#00b569" d="M31.303,6.745l-2.345,13.497l11.153-11.153L31.303,6.745z"/>
            <path fill="#00b569" d="M17.404,41.919l-2.345-13.497l-11.153,11.153L17.404,41.919z"/>
            <path fill="#00b569" d="M15.059,28.421l-2.828-2.828c-0.632,0.725-1.191,1.497-1.674,2.317l2.892,2.892 C13.889,29.951,14.436,29.171,15.059,28.421z"/>
            <path fill="#00b569" d="M44.801,9.089l-11.153,11.153l2.345,13.497l11.153-11.153L44.801,9.089z"/>
            <path fill="#00b569" d="M3.906,39.574l11.153-11.153l-2.345-13.497L3.906,39.574z"/>
        </SvgIcon>
    );
}

export default Sync;
