import React from 'react';
import {
    Datagrid,
    DateField,
    TextInput,
    FilterButton, ImageField,
    List,
    NumberField,
    RecordContextProvider,
    ReferenceField,
    ShowButton,
    TextField,
    TopToolbar,
    useListContext
} from 'react-admin';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery
} from "@mui/material";
import TimeCachDayField from "./TimeCachDayField";

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
    </TopToolbar>
);

function EmptyPlayerInfo() {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                Bạn chưa có người chơi nào
            </Typography>
        </Box>
    )
}

const postFilters = [
    <TextInput label="Search" source="_gameid" alwaysOn />,
];

const PlayerInfoListMobile= () => {
    const {data} = useListContext();
    let idx = 1;
    return (
        <Box margin="0.5em">
            {
                data.map(record => (
                    <RecordContextProvider key={record.id} value={record}>
                        <Card
                            sx={{
                                margin: '0.5rem 0',
                            }}
                            variant="outlined"
                        >
                            <CardHeader
                                title={
                                    <>
                                        {idx++ + '. '}
                                        <TextField
                                            source="name"
                                            variant="body1"
                                        />
                                        <TextField source="deviceId" variant="body1" label={"Mã máy"}/>
                                    </>
                                }
                                titleTypographyProps={{variant: 'body1'}}
                                action={<ShowButton/>}
                            />
                            <CardContent sx={{pt: 0}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label={'menu.about'} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Id: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TextField source="id" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Game: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <ReferenceField label="Game" source="gameId"
                                                                    reference="GameItem"
                                                                    link="show">
                                                        <TextField source="name"/>
                                                    </ReferenceField>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Mã máy: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TextField source="deviceId" label={"Mã máy"}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Số Ván: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="soVan" label={"Số Ván"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Đã dùng: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="timeUsed" label={"Đã dùng"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Cách đây: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="timeCachDay" label={"Cách đây"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Lần chơi cuối: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <DateField source="lastUpdate" showTime label={"Lần chơi cuối"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Bắt đầu chơi: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <DateField source="create_at" showTime label={"Bắt đầu chơi"} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </RecordContextProvider>
                ))
            }
        </Box>
    );
}

function PlayerinfoList(props) {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
        isXSmall ? (
            // <List
            //     emptyWhileLoading
            //     {...props}
            //     filters={postFilters}
            //     actions={<ListActions/>}
            //     empty={<EmptyPlayerInfo/>}
            // >
            //     <PlayerInfoListMobile/>
            // </List>
            <List
                {...props}
                filters={postFilters}
                actions={<ListActions/>}
                empty={<EmptyPlayerInfo/>}
            >
                <Datagrid rowClick="show">
                    <ReferenceField label="Game" source="gameId"
                                    reference="GameItem"
                                    link="show">
                        {/*<TextField source="name"/>*/}
                        <ImageField
                            sx={{'& img': {maxWidth: 50, maxHeight: 50, objectFit: 'contain'}}}
                            source="icon"
                        />
                    </ReferenceField>
                    <TextField source="deviceId" label={"Mã máy"}/>
                    <TimeCachDayField source="timeCachDay" label={"Cách đây"} />
                </Datagrid>
            </List>
        ) : (
                <>
                    <List
                        {...props}
                        filters={postFilters}
                        actions={<ListActions/>}
                        empty={<EmptyPlayerInfo/>}
                    >
                        <Datagrid rowClick="show">
                            <ReferenceField label="Game" source="gameId"
                                            reference="GameItem"
                                            link="show">
                                <ImageField
                                    sx={{'& img': {maxWidth: 50, maxHeight: 50, objectFit: 'contain'}}}
                                    source="icon"
                                />
                            </ReferenceField>
                            <TextField source="deviceId" label={"Mã máy"}/>
                            <TextField source="deviceName" label={"Device Name"}/>
                            <NumberField source="soVan" label={"Số Ván"} />
                            <NumberField source="timeUsed" label={"Đã dùng"} />
                            <TimeCachDayField source="timeCachDay" label={"Cách đây"} />
                        </Datagrid>
                    </List>
                </>
            )
    );
}

export default PlayerinfoList;
