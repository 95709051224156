import React, {useState} from 'react';
import {DashboardMenuItem, MenuItemLink, usePermissions, useSidebarState} from "react-admin";
import {Box} from "@mui/material";
import UserIcon from '@mui/icons-material/Group';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SubMenu from "./SubMenu";
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import RuleIcon from '@mui/icons-material/Rule';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import PersonIcon from '@mui/icons-material/Person';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CollectionsIcon from '@mui/icons-material/Collections';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import ScaleIcon from '@mui/icons-material/Scale';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';

function CustomMenu(props) {
    const {permissions} = usePermissions();
    const dense = props.dense;
    const [state, setState] = useState({
        menuCatalog: true,
        gameCenter: false,
        menuSales: false,
        menuCustomers: false,
        menuGym: true,
    });
    const [open] = useSidebarState();

    function handleToggle(menuCustomers) {
        setState(state => ({...state, [menuCustomers]: !state[menuCustomers]}));
    }

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {permissions === 'admin' &&
                <MenuItemLink
                    to="/PlayerInfo"
                    state={{_scrollToTop: true}}
                    primaryText={'Player Info'}
                    leftIcon={<SportsKabaddiIcon/>}
                    dense={dense}
                />
            }
            {permissions === 'admin' &&
                <MenuItemLink
                    to="/GameItem"
                    state={{_scrollToTop: true}}
                    primaryText={'Game Item'}
                    leftIcon={<SportsEsportsIcon/>}
                    dense={dense}
                />
            }
            {permissions === 'admin' &&
                <SubMenu
                    handleToggle={() => handleToggle('gameCenter')}
                    isOpen={state.gameCenter}
                    name="Game center"
                    icon={<MoreVertIcon/>}
                    dense={dense}
                >
                    {permissions === 'admin' &&
                        <DashboardMenuItem/>
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/gamechannel"
                            state={{_scrollToTop: true}}
                            primaryText={'Game Channel'}
                            leftIcon={<SubscriptionsIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/BauCuaRule"
                            state={{_scrollToTop: true}}
                            primaryText={'Bau Cua Rule'}
                            leftIcon={<RuleIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/DeviceActiveRule"
                            state={{_scrollToTop: true}}
                            primaryText={'Devices Active'}
                            leftIcon={<DevicesOtherIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/GameImages"
                            state={{_scrollToTop: true}}
                            primaryText={'Game Images'}
                            leftIcon={<CollectionsIcon/>}
                            dense={dense}
                        />
                    }
                </SubMenu>
                    }

            {permissions === 'admin' &&
                <SubMenu
                    handleToggle={() => handleToggle('menuSales')}
                    isOpen={state.menuSales}
                    name="Users"
                    icon={<MoreVertIcon/>}
                    dense={dense}
                >
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/user"
                            state={{_scrollToTop: true}}
                            primaryText={'user'}
                            leftIcon={<PersonIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/GameKhach"
                            state={{_scrollToTop: true}}
                            primaryText={'Game Khách'}
                            leftIcon={<UserIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/DetailAdd"
                            state={{_scrollToTop: true}}
                            primaryText={'Address Detail'}
                            leftIcon={<PersonPinCircleIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/AccGeo"
                            state={{_scrollToTop: true}}
                            primaryText={'Account Geocoding'}
                            leftIcon={<AccountBoxIcon/>}
                            dense={dense}
                        />
                    }
                    {/*<MenuItemLink*/}
                    {/*    to="/LoaiTaiSan"*/}
                    {/*    state={{ _scrollToTop: true }}*/}
                    {/*    primaryText={`Loại tài sản`}*/}
                    {/*    leftIcon={<DynamicFeedIcon />}*/}
                    {/*    dense={dense}*/}
                    {/*/>*/}
                    {/*<MenuItemLink*/}
                    {/*    to="/TaiSan"*/}
                    {/*    state={{ _scrollToTop: true }}*/}
                    {/*    primaryText={`Tài sản`}*/}
                    {/*    leftIcon={<TwoWheelerIcon />}*/}
                    {/*    dense={dense}*/}
                    {/*/>*/}
                    {/*<MenuItemLink*/}
                    {/*    to="/TatToan"*/}
                    {/*    state={{ _scrollToTop: true }}*/}
                    {/*    primaryText={`Thành công`}*/}
                    {/*    leftIcon={<DoneOutlineIcon />}*/}
                    {/*    dense={dense}*/}
                    {/*/>*/}
                    {/*<MenuItemLink*/}
                    {/*    to="/DongLai"*/}
                    {/*    state={{ _scrollToTop: true }}*/}
                    {/*    primaryText={`Đóng lãi`}*/}
                    {/*    leftIcon={<CurrencyExchangeIcon />}*/}
                    {/*    dense={dense}*/}
                    {/*/>*/}
                    {/*<MenuItemLink*/}
                    {/*    to="/TienGoc"*/}
                    {/*    state={{ _scrollToTop: true }}*/}
                    {/*    primaryText={`Tiền gốc`}*/}
                    {/*    leftIcon={<MonetizationOnIcon />}*/}
                    {/*    dense={dense}*/}
                    {/*/>*/}
                </SubMenu>
            }
            {permissions === 'admin' &&
                <SubMenu
                    handleToggle={() => handleToggle('menuCustomers')}
                    isOpen={state.menuCustomers}
                    name="Doanh Thu"
                    icon={<MoreVertIcon/>}
                    dense={dense}
                >
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/Customer"
                            state={{_scrollToTop: true}}
                            primaryText={'Khách hàng'}
                            leftIcon={<PersonIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/ExpenseItem"
                            state={{_scrollToTop: true}}
                            primaryText={'Chi Phí'}
                            leftIcon={<PersonPinCircleIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/GeneralExpense"
                            state={{_scrollToTop: true}}
                            primaryText={'Chi Phí Chung'}
                            leftIcon={<AccountBoxIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/IncomeItem"
                            state={{_scrollToTop: true}}
                            primaryText={'Doanh Thu'}
                            leftIcon={<AccountBoxIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/Expense"
                            state={{_scrollToTop: true}}
                            primaryText={'Tổng Quan Chi Phí'}
                            leftIcon={<UserIcon/>}
                            dense={dense}
                        />
                    }
                    {permissions === 'admin' &&
                        <MenuItemLink
                            to="/SalesItem"
                            state={{_scrollToTop: true}}
                            primaryText={'Tổng Quan Doanh Thu'}
                            leftIcon={<AccountBoxIcon/>}
                            dense={dense}
                        />
                    }
                </SubMenu>
            }
            <SubMenu
                handleToggle={() => handleToggle('menuGym')}
                isOpen={state.menuGym}
                name="Gym"
                icon={<MoreVertIcon/>}
                dense={dense}
            >
                {(permissions === 'admin' || permissions === 'creator') &&
                    <MenuItemLink
                        to="/calendar"
                        state={{_scrollToTop: true}}
                        primaryText={'Tổng quan'}
                        leftIcon={<CalendarMonthIcon/>}
                        dense={dense}
                    />
                }
                {/*{(permissions === 'admin' || permissions === 'creator') &&*/}
                {/*    <MenuItemLink*/}
                {/*        to="/generalgym"*/}
                {/*        state={{_scrollToTop: true}}*/}
                {/*        primaryText={'Tổng quan'}*/}
                {/*        leftIcon={<SportsKabaddiIcon/>}*/}
                {/*        dense={dense}*/}
                {/*    />*/}
                {/*}*/}
                {/*{(permissions === 'admin' || permissions === 'creator') &&*/}
                {/*    <MenuItemLink*/}
                {/*        to="/CategoriesGym"*/}
                {/*        state={{_scrollToTop: true}}*/}
                {/*        primaryText={'Nhóm cơ'}*/}
                {/*        leftIcon={<SportsHandballIcon/>}*/}
                {/*        dense={dense}*/}
                {/*    />*/}
                {/*}*/}
                {(permissions === 'admin' || permissions === 'creator') &&
                    <MenuItemLink
                        to="/Exercises"
                        state={{_scrollToTop: true}}
                        primaryText={'Bài tập'}
                        leftIcon={<FitnessCenterIcon/>}
                        dense={dense}
                    />
                }
                {permissions === 'admin' &&
                    <MenuItemLink
                        to="/HistoryExercises"
                        state={{_scrollToTop: true}}
                        primaryText={'Lịch sử tập'}
                        leftIcon={<SportsMartialArtsIcon/>}
                        dense={dense}
                    />
                }
                {(permissions === 'admin' || permissions === 'creator') &&
                    <MenuItemLink
                        to="/WeightGym"
                        state={{_scrollToTop: true}}
                        primaryText={'Cân nặng'}
                        leftIcon={<ScaleIcon/>}
                        dense={dense}
                    />
                }
            </SubMenu>
        </Box>
    );
}

export default CustomMenu;
