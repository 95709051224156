import React, {useEffect, useState} from 'react';
import {NumberInput, required, SimpleForm, SelectInput, useDataProvider, useNotify, useRefresh, useRedirect} from "react-admin";
import {Button} from "@mui/material";

function ActionMoveToolbar() {
    return null;
}
function ActionMoveToDoanhThu(props) {
    const {iditem, closeCallback} = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [infoChoicesCustomer, setInfoChoicesCustomer] = useState();
    useEffect(() => {
        const refreshRecord = () => {
            dataProvider.getOne(`Customer`, {id:'Choices'})
                .then((resources) => {
                    setInfoChoicesCustomer(resources.data);
                })
                .catch((error) => {
                    notify(`Lỗi: ${error.body}`, {type: 'error'});
                });
        };
        refreshRecord();
    }, [dataProvider, notify]);
    const postMoveToDoanhThu = (data) => {
        dataProvider
            .create('IncomeItem/fromdeviceactive', {
                data: {
                    amount: data.amount,
                    idCustomer: data.customer,
                    deviceActiveId: iditem
                },
            })
            .then((resources) => {
                // console.log(resources.data)
                // console.log('id item='+resources.data.id)
                // closeCallback();
                // refresh();
                // notify('Chuyển vào mục doanh thu thành công', {type: 'success'});
                redirect('/IncomeItem/'+resources.data.id+'/show');
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    }
    return (
        <SimpleForm
            onSubmit={postMoveToDoanhThu}
            toolbar={<ActionMoveToolbar/>}
        >
            {infoChoicesCustomer && (<NumberInput fullWidth source="amount" label={"Số tiền"} validate={required()}/>) }
            {infoChoicesCustomer && (<SelectInput fullWidth source="customer" choices={infoChoicesCustomer?.items} validate={required()} />) }
            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                {'Chuyển sang doanh thu'}
            </Button>

        </SimpleForm>
    );
}

export default ActionMoveToDoanhThu;
