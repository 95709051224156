import React from 'react';
import {Datagrid, DateField, ImageField, List, NumberField, TextField} from 'react-admin';
import TrangThaiGameField from "../TrangThaiGameField";
function GamekhachList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="name" />
                <NumberField source="typeGame" label={"Kiểu Game"} />
                <NumberField source="idAdmin" label={"Chủ Game"} />
                <TrangThaiGameField source="status" label={"Trạng Thái"} />
                <ImageField
                    sx={{'& img': {maxWidth: 60, maxHeight: 60, objectFit: 'contain'}}}
                    source="icon"
                />
                <DateField source="create_at" />
            </Datagrid>
        </List>
    );
}

export default GamekhachList;
