import React from 'react';
import {Button} from "@mui/material";
import {required, SimpleForm, TextInput, useDataProvider, useNotify, useRecordContext, useRefresh} from "react-admin";

function UserEditPasswordAdminToolbar() {
    return null;
}

function UserEditChangePasswordAdmin(props) {
    const {closeCallback} = props;
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const profile = (dataPut) => {
        const diff = {
            newPassword: dataPut.newPassword,
            idIdenUser: record.id,
        };
        dataProvider
            .update('user/admin/password', {
                id: record.userInfo.id,
                data: diff,
            })
            .then(() => {
                closeCallback();
                refresh();
                notify('Cập nhật thành công',{ type: 'success' });
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    };
    if (!record) return null;
    return (
        <SimpleForm
            onSubmit={profile}
            toolbar={<UserEditPasswordAdminToolbar/>}
        >
            <TextInput
                source="newPassword"
                label="Mật khẩu mới"
                validate={
                    [required(),
                        (value) => {
                            if (value.length < 6) {
                                return 'Mật khẩu phải có ít nhất 6 ký tự';
                            }
                            return undefined;
                        }]
                }
                fullWidth
            />

            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                Cập nhật
            </Button>
        </SimpleForm>
    );
}

export default UserEditChangePasswordAdmin;