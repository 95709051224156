import React from 'react';
import {
    DeleteButton,
    FormTab,
    ImageField,
    ReferenceField,
    SaveButton,
    TabbedForm,
    TextField,
    Toolbar
} from "react-admin";
import {Grid, Typography} from "@mui/material";
import ExercisesTodayShow from "../ExercisesTodayShow";
import HistoryInExercisesShow from "../HistoryInExercisesShow";
import GeneralResult from "./GeneralResult";

function GeneralGym(props) {

    const PostEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton disabled/>
            <DeleteButton disabled/>
        </Toolbar>
    );
    return (
        <TabbedForm toolbar={<PostEditToolbar/>}>
            <FormTab label="Result">
                <Typography variant="h6" gutterBottom component="div">
                    Result
                </Typography>
                <GeneralResult  istarget={0}/>
            </FormTab>
            <FormTab label="Target">
                <Typography variant="h6" gutterBottom component="div">
                    Target
                </Typography>
                <GeneralResult  istarget={1}/>
            </FormTab>
        </TabbedForm>
    );
}

export default GeneralGym;
