import React from 'react';
import {useRecordContext} from "react-admin";
import {Chip} from "@mui/material";

function FieldActiveRule(props) {
    const {id} = props;
    const record =useRecordContext();
    if (!record) {
        return null;
    }
    const active = id === 1 ? record.activeR : record.activeA;
    return (
        <Chip
            size="small"
            key={ active}
            label={active===1 ? "Luật Theo Mã" : active===2 ? "Luật Tất Cả" : "Tắt"}
            color={active===1 ? "primary" : active===2 ? "success" :"error"}
        />
    );
}

export default FieldActiveRule;
