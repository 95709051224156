import React from 'react';
import {Button} from "@mui/material";
import {
    AutocompleteInput,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
    useDataProvider,
    useNotify,
    useRefresh
} from "react-admin";

function GameItemBatLuatToolbar() {
    return null;
}

function GameItemBatLuat(props) {
    const {idgame, closeCallback} = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    // const choiseBatLuat = [
    //     {id: 0, name: 'Tắt'},
    //     {id: 1, name: 'Bật luật con'},
    //     {id: 2, name: 'Bật luật cái'}
    // ];
    const postBatLuat = (data) => {
        dataProvider
            .create('GameItem/BatLuat', {
                data: {
                    deviceId: data.deviceId,
                    timeUsed: data.timeUsed,
                    typeRule: 1,
                    gameId: idgame
                },
            })
            .then(() => {
                closeCallback();
                refresh();
                notify('Bật luật thành công', {type: 'success'});
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    }
    return (
        <SimpleForm
            onSubmit={postBatLuat}
            toolbar={<GameItemBatLuatToolbar/>}
        >
            <TextInput source="deviceId" label={"Mã máy"} validate={required()}/>
            <NumberInput source="timeUsed" defaultValue={60} label={"Số phút dùng"} validate={required()}/>
            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                {'Bật luật'}
            </Button>

        </SimpleForm>
    );
}

export default GameItemBatLuat;
