import React from 'react';
import {Create, NumberInput, SimpleForm, TextInput} from "react-admin";
import {Grid} from "@mui/material";

function GeneralExpenseCreate(props) {
    return (
        <Create
            redirect="show"
        >
            <SimpleForm>
                <NumberInput label={"Số tiền"} source="amount"/>
                <TextInput multiline fullWidth source="note"/>
            </SimpleForm>
        </Create>
    );
}

export default GeneralExpenseCreate;
