import React from 'react';
import {Create, Edit, NumberInput, SimpleForm, TextInput} from "react-admin";
import {Grid} from "@mui/material";

const getCurrentDay = () => {
    const currentDate = new Date();
    return currentDate.getDate(); // Tháng trong JavaScript bắt đầu từ 0, nên cộng 1 để lấy tháng hiện tại.
};
const getCurrentMonth = () => {
    const currentDate = new Date();
    return currentDate.getMonth() + 1; // Tháng trong JavaScript bắt đầu từ 0, nên cộng 1 để lấy tháng hiện tại.
};
const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear(); // Năm trong JavaScript bắt đầu từ 0, nên cộng 1 để lấy năm hiện tại.
}
function WeightgymCreate(props) {
    return (
        <Create>
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <NumberInput defaultValue={getCurrentDay()} required fullWidth source="day" label={"Ngày"} />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput defaultValue={getCurrentMonth()} required fullWidth source="month" label={"Tháng"} />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput defaultValue={getCurrentYear()} required fullWidth source="year" label={"Năm"} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <NumberInput required fullWidth source="weight" label={"Cân nặng"} />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput required defaultValue={0} fullWidth source="waist" label={"Vòng eo"} />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput required defaultValue={0} fullWidth source="height" label={"Chiều cao"} />
                    </Grid>
                </Grid>
                <TextInput fullWidth source="note" />
                <TextInput fullWidth source="image1" />
                <TextInput fullWidth source="image2" />
                <TextInput fullWidth source="image3" />
            </SimpleForm>
        </Create>
    );
}

export default WeightgymCreate;
