import React from 'react';
import { Datagrid, DateField, List, TextField, NumberField } from 'react-admin';
function CustomerList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="name" />
                <NumberField label={"Số đơn"} source="numberOrder"/>
                <TextField source="phone" />
                <TextField source="note" />
                <DateField source="create_at" />
            </Datagrid>
        </List>
    );
}

export default CustomerList;
