import React from 'react';
import {Edit, NumberInput, SimpleForm, TextInput, AutocompleteInput} from 'react-admin';
import {Grid} from "@mui/material";

function GameitemEdit(props) {
    const choiseStatus = [
        {id: 0, name: 'Đã Chết'},
        {id: 1, name: 'Còn Sống'},
    ];
    return (
        <Edit>
            <SimpleForm>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="name" fullWidth label={"Tên Game"}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="url" title="link" fullWidth multiline/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NumberInput fullWidth source="codeCheckActive" label={"Code check active"} />
                    </Grid>
                    <Grid item xs={6}>
                        <AutocompleteInput
                            source="status"
                            fullWidth
                            label="Status"
                            choices={choiseStatus}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NumberInput fullWidth source="idUI" label={"Id UI"} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput fullWidth source="icon"/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput source="description" title="Note" fullWidth multiline/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
}

export default GameitemEdit;
