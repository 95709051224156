import React from 'react';
import {
    DateField,
    ImageField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    Title,
    UrlField
} from 'react-admin';
import {
    Box, Button,
    Card,
    CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import TypeImageField from "./TypeImageField";

function GameimageShow(props) {
    return (
        <Show>
            <Card>
                <Title
                    title={'Chi tiết'}
                >
                    <Typography>
                        {'Thông tin'}
                    </Typography>
                </Title>
                <CardContent>
                    <Box display="flex">
                        <Box flex="1">
                            <TextField
                                source="name"
                                variant={"h5"}
                                component={"h2"}
                            />
                            <Box height={10}>&nbsp;</Box>
                            <TableContainer component={Paper}>
                                <Table aria-label={'menu.about'} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Id: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <NumberField source="id"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Game: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <ReferenceField label="Game" source="gameId"
                                                                reference="GameItem"
                                                                link="show">
                                                    <TextField source="name"/>
                                                </ReferenceField>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Ảnh: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <ImageField source="url"/>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Kiểu Image: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TypeImageField source="typeImage"/>
                                                {/*<NumberField source="typeImage"/>*/}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Id UI: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="idUI"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Ngày tạo: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DateField source="create_at" showTime label={"Ngày tạo"}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Show>
    );
}

export default GameimageShow;
