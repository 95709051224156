import React from 'react';
import {
    DateInput,
    Edit,
    NumberInput,
    ReferenceField,
    ReferenceInput, SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
function IncomeItemEdit(props) {
    const choices = [
        { id: 0, name: 'Chưa trả' },
        { id: 1, name: 'Đã trả' }
    ];
    return (
        <Edit>
            <SimpleForm>
                <Box display="flex">
                    <Box flex="1">
                        <TableContainer component={Paper}>
                            <Table aria-label={'menu.about'} size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right" component="th" scope="row">
                                            <NumberInput label={"Game Id"} source="gameId" />
                                        </TableCell>
                                        <TableCell align="left">
                                            <NumberInput label={"Id khách hàng"} source="idCustomer" />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right" component="th" scope="row">
                                            <NumberInput source="month" />
                                        </TableCell>
                                        <TableCell align="left">
                                            <NumberInput source="year" />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right" component="th" scope="row">
                                            <NumberInput label={"Số tiền"} source="amount" />
                                        </TableCell>
                                        <TableCell align="left">
                                            {/*<NumberInput source="status" />*/}
                                            <SelectInput source="status" choices={choices} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <TextInput multiline fullWidth source="note" />
                <TextInput multiline fullWidth source="description" />
            </SimpleForm>
        </Edit>
    );
}

export default IncomeItemEdit;
