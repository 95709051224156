import React from 'react';
import {NumberInput, required, SelectInput, SimpleForm, useDataProvider, useNotify, useRedirect} from "react-admin";
import {Button} from "@mui/material";

function ActionBatLuatToolbar() {
    return null;
}

function ActionBatLuat(props) {
    const {iditem, closeCallback} = props;
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const postBatLuat = (data) => {
        dataProvider
            .create('GameItem/BatLuatPlayerInfo', {
                data: {
                    timeUsed: data.timeTurnOn,
                    playerInfoId: iditem
                },
            })
            .then((resources) => {
                redirect('/DeviceActiveRule/'+resources.data.id+'/show');
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    }
    return (
        <SimpleForm
            onSubmit={postBatLuat}
            toolbar={<ActionBatLuatToolbar/>}
        >
            <NumberInput fullWidth source="timeTurnOn" label={"Số phút dùng"} validate={required()} defaultValue={60}/>
            <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
            >
                {'Bật luật'}
            </Button>

        </SimpleForm>
    );
}

export default ActionBatLuat;
