import React from 'react';
import {
    CreateButton,
    Datagrid,
    DateField,
    FilterButton,
    List,
    NumberField,
    RecordContextProvider,
    ReferenceField,
    ShowButton,
    TextField,
    TopToolbar,
    useListContext
} from 'react-admin';
import TypeRuleActive from "./TypeRuleActive";
import FieldDeviceActiveStatus from "./FieldDeviceActiveStatus";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery
} from "@mui/material";

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
    </TopToolbar>
);

function EmptyGameChannel() {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                Bạn chưa bật luật cho mã nào
            </Typography>
            <Typography variant="body1">
                Bạn hãy nhấn vào nút ở dưới để tạo mới.
            </Typography>
            <CreateButton
                variant="contained"
                sx={{mt: 2}}
                label="Thêm Kênh Game"
            />
        </Box>
    )
}

const postFilters = [
];

const DeviceActiveRuleListMobile = () => {
    const {data} = useListContext();
    let idx = 1;
    return (
        <Box margin="0.5em">
            {
                data.map(record => (
                    <RecordContextProvider key={record.id} value={record}>
                        <Card
                            sx={{
                                margin: '0.5rem 0',
                            }}
                            variant="outlined"
                        >
                            <CardHeader
                                title={
                                    <>
                                        {idx++ + '. '}
                                        <TextField
                                            source="deviceId"
                                            variant="body1"
                                        />
                                    </>
                                }
                                titleTypographyProps={{variant: 'body1'}}
                                action={<ShowButton/>}
                            />
                            <CardContent sx={{pt: 0}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label={'menu.about'} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Game: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <ReferenceField label="Game" source="gameId"
                                                                    reference="GameItem"
                                                                    link="show">
                                                        <TextField source="name"/>
                                                    </ReferenceField>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Mã máy: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TextField source="deviceId" label={"Mã máy"}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Thời gian sử dụng: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="minuteUse" label={"Thời gian sử dụng"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Thời gian còn lại: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="conLai" label={"Thời gian còn lại"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Trạng Thái: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <FieldDeviceActiveStatus source="status" label={"Trạng Thái"}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Bật Lúc: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <DateField source="create_at" showTime label={"Tạo Lúc"} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </RecordContextProvider>
                ))
            }
        </Box>
    );
}

function DeviceactiveruleList(props) {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
        isXSmall ? (
            <List
                emptyWhileLoading
                {...props}
                filters={postFilters}
                actions={<ListActions/>}
                empty={<EmptyGameChannel/>}
            >
                <DeviceActiveRuleListMobile/>
            </List>
        ) : (
                <>
                    <List
                        {...props}
                        filters={postFilters}
                        actions={<ListActions/>}
                        empty={<EmptyGameChannel/>}
                    >
                        <Datagrid rowClick="show">
                            <NumberField source="id" label={"id"} />
                            <ReferenceField label="Game" source="gameId"
                                            reference="GameItem"
                                            link="show">
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextField source="deviceId" label={"Mã máy"}/>
                            <NumberField source="minuteUse" label={"Thời gian sử dụng"} />
                            <NumberField source="conLai" label={"Thời gian còn lại"} />
                            <FieldDeviceActiveStatus source="status" label={"Trạng Thái"}/>
                            <DateField source="create_at" showTime label={"Bật Lúc"} />
                        </Datagrid>
                    </List>
                </>
            )
    );
}

export default DeviceactiveruleList;
