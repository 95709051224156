import React, {useEffect, useState} from 'react';
import {
    DateField,
    ImageField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    Title, useDataProvider, useNotify
} from 'react-admin';
import {
    Box,
    Button,
    Card,
    CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
    Paper,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import ShopIcon from "@mui/icons-material/Shop";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {Link, useParams} from "react-router-dom";
import {stringify} from "query-string";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TrangThaiGameField from "../TrangThaiGameField";
import GameItemBatLuat from "../GameItem/GameItemAction/GameItemBatLuat";
import GameItemDangBat from "../GameItem/GameItemAction/GameItemDangBat";
import GameItemXemLuat from "../GameItem/GameItemAction/GameItemXemLuat";
import GameItemSuaLuat from "../GameItem/GameItemAction/GameItemSuaLuat";

function GamekhachShow(props) {
    const {id} = useParams();
    const [infoGameItem, setInfoGameItem] = useState();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    useEffect(() => {
        return () => {
            dataProvider.getOne(`GameKhach`, {
                id: id
            })
                .then((resources) => {
                    setInfoGameItem(resources.data);
                })
                .catch((error) => {
                    notify(`Lỗi: ${error.body}`, {type: 'error'});
                })
        };
    }, [id, dataProvider]);
    return (
        <Show>
            <Card>
                <Title
                    title={'pos.configuration'}
                >
                    <Typography>
                        {'Thông tin'}
                    </Typography>
                </Title>
                <CardContent>
                    {infoGameItem && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Button
                                variant="outlined"
                                href={infoGameItem?.urlCHPlay}
                                startIcon={<ShopIcon/>}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{color: 'green'}}
                            >
                                CH Play
                            </Button>
                        </Box>
                    )}
                    <Box display="flex">
                        <Box flex="1">
                            <TextField
                                source="name"
                                variant={"h5"}
                                component={"h2"}
                            />
                            <Box height={10}>&nbsp;</Box>
                            <TableContainer component={Paper}>
                                <Table aria-label={'menu.about'} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'id: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <TextField source="id" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Tên Game: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <TextField source="name" label={"Tên Game"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Kiểu Game: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <NumberField source="typeGame" label={"Kiểu Game"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Chủ Game: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <NumberField source="idAdmin" label={"Chủ Game"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Icon: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <ImageField
                                                    sx={{'& img': {maxWidth: 60, maxHeight: 60, objectFit: 'contain'}}}
                                                    source="icon"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Link Firebase: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <TextField source="urlRTFB" label={"Link Realtime Firebase Database"} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'apiKey: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <TextField source="apiKey" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'authDomain: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <TextField source="authDomain" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Trạng Thái: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TrangThaiGameField source="status" label="Trạng Thái"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'packageName: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="packageName" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'username: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <TextField source="username" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Password: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="password" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Ngày Tạo: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DateField source="create_at" label={"Ngày Tạo"}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Show>
    );
}

export default GamekhachShow;
