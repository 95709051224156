import jsonServerProvider from 'ra-data-json-server';
import './App.css';
import {Admin, CustomRoutes, EditGuesser, fetchUtils, ListGuesser, Resource, ShowGuesser} from 'react-admin';
import {loadToken} from "./oauth";
import authProvider from "./authProvider";
import Dashboard from "./dashboard/Dashboard";
import {UserCreate} from "./users/UserCreate";
import UserList from "./users/UserList";
import CustomLayout from "./Layout/CustomLayout";
import {Route} from 'react-router';
import Configuration from "./Configuration/Configuration";
import UserShow from "./users/UserShow";
import GamechannelList from "./Game/GameChannel/GamechannelList";
import GameitemList from "./Game/GameItem/GameitemList";
import GamechannelShow from "./Game/GameChannel/GamechannelShow";
import GamechannelEdit from "./Game/GameChannel/GamechannelEdit";
import GameitemEdit from "./Game/GameItem/GameitemEdit";
import GameitemShow from "./Game/GameItem/GameitemShow";
import PlayerinfoList from "./Game/PlayerInfo/PlayerinfoList";
import PlayerinfoEdit from "./Game/PlayerInfo/PlayerinfoEdit";
import PlayerinfoShow from "./Game/PlayerInfo/PlayerinfoShow";
import BaucuaruleList from "./Game/BauCuaRule/BaucuaruleList";
import BaucuaruleEdit from "./Game/BauCuaRule/BaucuaruleEdit";
import baucuaruleShow from "./Game/BauCuaRule/BaucuaruleShow";
import DeviceactiveruleList from "./Game/DeviceActiveRule/DeviceactiveruleList";
import DeviceactiveruleEdit from "./Game/DeviceActiveRule/DeviceactiveruleEdit";
import DeviceactiveruleShow from "./Game/DeviceActiveRule/DeviceactiveruleShow";
import GamechannelCreate from "./Game/GameChannel/GamechannelCreate";
import GamekhachList from "./Game/GameKhach/GamekhachList";
import GamekhachEdit from "./Game/GameKhach/GamekhachEdit";
import GamekhachShow from "./Game/GameKhach/GamekhachShow";
import GamekhachCreate from "./Game/GameKhach/GamekhachCreate";
import DetailaddList from "./Game/DetailAddress/DetailaddList";
import DetailaddEdit from "./Game/DetailAddress/DetailaddEdit";
import DetailaddShow from "./Game/DetailAddress/DetailaddShow";
import AccgeoList from "./Game/AccountGeocoding/AccgeoList";
import AccgeoShow from "./Game/AccountGeocoding/AccgeoShow";
import AccgeoEdit from "./Game/AccountGeocoding/AccgeoEdit";
import AccgeoCreate from "./Game/AccountGeocoding/AccgeoCreate";
import CustomerList from "./Game/DoanhThu/Customer/CustomerList";
import CustomerShow from "./Game/DoanhThu/Customer/CustomerShow";
import CustomerEdit from "./Game/DoanhThu/Customer/CustomerEdit";
import ExpenseItemList from "./Game/DoanhThu/ExpenseItem/ExpenseItemList";
import ExpenseItemShow from "./Game/DoanhThu/ExpenseItem/ExpenseItemShow";
import ExpenseItemEdit from "./Game/DoanhThu/ExpenseItem/ExpenseItemEdit";
import GeneralExpenseList from "./Game/DoanhThu/GeneralExpense/GeneralExpenseList";
import GeneralExpenseShow from "./Game/DoanhThu/GeneralExpense/GeneralExpenseShow";
import GeneralExpenseEdit from "./Game/DoanhThu/GeneralExpense/GeneralExpenseEdit";
import IncomeItemList from "./Game/DoanhThu/IncomeItem/IncomeItemList";
import IncomeItemShow from "./Game/DoanhThu/IncomeItem/IncomeItemShow";
import IncomeItemEdit from "./Game/DoanhThu/IncomeItem/IncomeItemEdit";
import ExpenseList from "./Game/DoanhThu/Expense/ExpenseList";
import ExpenseShow from "./Game/DoanhThu/Expense/ExpenseShow";
import ExpenseEdit from "./Game/DoanhThu/Expense/ExpenseEdit";
import SalesItemList from "./Game/DoanhThu/SalesItem/SalesItemList";
import SalesItemShow from "./Game/DoanhThu/SalesItem/SalesItemShow";
import SalesItemEdit from "./Game/DoanhThu/SalesItem/SalesItemEdit";
import CustomerCreate from "./Game/DoanhThu/Customer/CustomerCreate";
import ExpenseItemCreate from "./Game/DoanhThu/ExpenseItem/ExpenseItemCreate";
import GeneralExpenseCreate from "./Game/DoanhThu/GeneralExpense/GeneralExpenseCreate";
import IncomeItemCreate from "./Game/DoanhThu/IncomeItem/IncomeItemCreate";
import GameimageList from "./Game/GameImages/GameimageList";
import GameimageEdit from "./Game/GameImages/GameimageEdit";
import GameimageShow from "./Game/GameImages/GameimageShow";
import Calendar from "./Gym/Calendar";
import CategoryList from "./Gym/Category/CategoryList";
import CategoriesgymEdit from "./Gym/Category/CategoriesgymEdit";
import CategoriesgymShow from "./Gym/Category/CategoriesgymShow";
import CategoriesgymCreate from "./Gym/Category/CategoriesgymCreate";
import HistoryexerciseList from "./Gym/HistoryExercises/HistoryexerciseList";
import HistoryexerciseEdit from "./Gym/HistoryExercises/HistoryexerciseEdit";
import HistoryexerciseShow from "./Gym/HistoryExercises/HistoryexerciseShow";
import ExerciseList from "./Gym/Exercises/ExerciseList";
import ExerciseShow from "./Gym/Exercises/ExerciseShow";
import ExerciseEdit from "./Gym/Exercises/ExerciseEdit";
import ExerciseCreate from "./Gym/Exercises/ExerciseCreate";
import WeightgymList from "./Gym/WeightGym/WeightgymList";
import WeightgymEdit from "./Gym/WeightGym/WeightgymEdit";
import WeightgymShow from "./Gym/WeightGym/WeightgymShow";
import WeightgymCreate from "./Gym/WeightGym/WeightgymCreate";
import GeneralGym from "./Gym/Exercises/GeneralGym/GeneralGym";


const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  options.headers.set('X-Custom-Header', 'foobar');
  options.headers.set('Authorization', `Bearer ${loadToken()}`);
  return fetchUtils.fetchJson(url, options);
};

const url = `${process.env.REACT_APP_AUTH_SERVER_URL}`;

function App() {
  return (
      <Admin
          dataProvider={jsonServerProvider(url, httpClient)}
          // dataProvider={BaseDataProviders}
          authProvider={authProvider}
          dashboard={Dashboard}
          layout={CustomLayout}
      >
        <CustomRoutes>
          <Route path="/configuration" element={<Configuration />} />
        </CustomRoutes>
        <Resource
            name="user"
            create={UserCreate}
            list={UserList}
            show={UserShow}
        />
        <Resource
            name="gamechannel"
            options={{ label: 'Channel' }}
            list={GamechannelList}
            show={GamechannelShow}
            edit={GamechannelEdit}
            create={GamechannelCreate}
        />
        <Resource
            name="GameItem"
            options={{ label: 'Game' }}
            list={GameitemList}
            show={GameitemShow}
            edit={GameitemEdit}
            create={EditGuesser}
        />
        <Resource
            name="PlayerInfo"
            options={{ label: 'Người Chơi' }}
            list={PlayerinfoList}
            show={PlayerinfoShow}
            edit={PlayerinfoEdit}
            create={EditGuesser}
        />
        <Resource
            name="BauCuaRule"
            options={{ label: 'Bầu Cua Rule' }}
            list={BaucuaruleList}
            show={baucuaruleShow}
            edit={BaucuaruleEdit}
            create={EditGuesser}
        />
        <Resource
            name="DeviceActiveRule"
            options={{ label: 'Mã đang bật' }}
            list={DeviceactiveruleList}
            show={DeviceactiveruleShow}
            edit={DeviceactiveruleEdit}
            create={EditGuesser}
        />
        <Resource
            name="GameImages"
            options={{ label: 'Game Images' }}
            list={GameimageList}
            show={GameimageShow}
            edit={GameimageEdit}
        />
        <Resource
            name="GameKhach"
            options={{ label: 'Game Khách' }}
            list={GamekhachList}
            show={GamekhachShow}
            edit={GamekhachEdit}
            create={GamekhachCreate}
        />
        <Resource
            name="DetailAdd"
            options={{ label: 'Address Detail' }}
            list={DetailaddList}
            show={DetailaddShow}
            edit={DetailaddEdit}
        />
        <Resource
            name="AccGeo"
            options={{ label: 'Account Geocoding' }}
            list={AccgeoList}
            show={AccgeoShow}
            edit={AccgeoEdit}
            create={AccgeoCreate}
        />
        <Resource
            name="Customer"
            options={{ label: 'Khách hàng' }}
            list={CustomerList}
            show={CustomerShow}
            edit={CustomerEdit}
            create={CustomerCreate}
        />
        <Resource
            name="ExpenseItem"
            options={{ label: 'Chi Phí' }}
            list={ExpenseItemList}
            show={ExpenseItemShow}
            edit={ExpenseItemEdit}
            create={ExpenseItemCreate}
        />
        <Resource
            name="GeneralExpense"
            options={{ label: 'Chi Phí Chung' }}
            list={GeneralExpenseList}
            show={GeneralExpenseShow}
            edit={GeneralExpenseEdit}
            create={GeneralExpenseCreate}
        />
        <Resource
            name="IncomeItem"
            options={{ label: 'Doanh Thu' }}
            list={IncomeItemList}
            show={IncomeItemShow}
            edit={IncomeItemEdit}
            create={IncomeItemCreate}
        />
        <Resource
            name="Expense"
            options={{ label: 'Tổng Quan Chi Phí' }}
            list={ExpenseList}
            show={ExpenseShow}
        />
        <Resource
            name="SalesItem"
            options={{ label: 'Tổng Quan Doanh Thu' }}
            list={SalesItemList}
            show={SalesItemShow}
        />
        <Resource name="calendar" list={Calendar} />
        {/*<Resource name="generalgym" list={GeneralGym} />*/}
        {/*<Resource*/}
        {/*    name="CategoriesGym"*/}
        {/*    list={CategoryList}*/}
        {/*    edit={CategoriesgymEdit}*/}
        {/*    create={CategoriesgymCreate}*/}
        {/*    show={CategoriesgymShow}*/}
        {/*    options={{ label: 'Nhóm cơ' }}*/}
        {/*/>*/}
        <Resource
            name="Exercises"
            list={ExerciseList}
            edit={ExerciseEdit}
            show={ExerciseShow}
            create={ExerciseCreate}
            options={{ label: 'Bài tập' }}
        />
        <Resource
            name="HistoryExercises"
            list={HistoryexerciseList}
            edit={HistoryexerciseEdit}
            show={HistoryexerciseShow}
            options={{ label: 'Lịch sử tập' }}
        />
        <Resource
            name="WeightGym"
            list={WeightgymList}
            edit={WeightgymEdit}
            create={WeightgymCreate}
            show={WeightgymShow}
            options={{ label: 'Cân nặng' }}
        />
      </Admin>
  );
}

export default App;
