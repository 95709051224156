import React from 'react';
import {useRecordContext} from "react-admin";
import {Chip, Stack} from "@mui/material";

const GetColor = (role) => {
    switch (role) {
        case "admin":
            return "warning";
        case "creator":
            return "secondary";
        default:
            return "default";
    }
}

function RolesField(props) {
    const record =useRecordContext();
    if (!record || !record.roles) {
        return null;
    }
    return (
        <Stack direction="row" gap={1} flexWrap="wrap">
            {record.roles.map(segmentId => (
                <Chip
                    size="small"
                    key={segmentId}
                    label={segmentId}
                    color={GetColor(segmentId)}
                />
            ))}
        </Stack>
    );
}

export default RolesField;