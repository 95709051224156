import React from 'react';
import {Datagrid, DateField, EmailField, List, NumberField, TextField} from 'react-admin';

function AccgeoList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="email" />
                <TextField source="api_key" />
                <NumberField
                    source="is_active"
                    label={"Đang dùng"}
                />
                <NumberField source="total_hour" />
                <NumberField source="total_request" />
                <NumberField source="moneyRemain" />
                <DateField source="create_at" />
            </Datagrid>
        </List>
    );
}

export default AccgeoList;
