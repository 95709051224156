import React from 'react';
import {useRecordContext} from "react-admin";
import {Chip} from "@mui/material";

function TrangThaiGameField(props) {
    const record =useRecordContext();
    if (!record) {
        return null;
    }
    return (
        <Chip
            size="small"
            key={record.status}
            label={record.status===1 ? "Còn Sống" : "Đã Chết"}
            // label={getTrangThaiHD(record.trangthai)}
            color={record.status===1 ? "success" : "error"}
        />
    );
}

export default TrangThaiGameField;
