import React, {forwardRef} from 'react';
import {Box, ListItemIcon, ListItemText, MenuItem, Typography, useMediaQuery} from "@mui/material";
import {AppBar, Logout, usePermissions, UserMenu} from "react-admin";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HelpIcon from '@mui/icons-material/Help';
import {Link} from 'react-router-dom';
import Logo from "./Logo";
import {AboutDialog} from "../Dialogs/AboutDialog";

const ConfigurationMenu = React.forwardRef((props, ref) => {
    return (
        <MenuItem
            component={Link}
            // @ts-ignore
            ref={ref}
            {...props}
            to="/configuration"
        >
            <ListItemIcon>
                <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText>{'Thông tin'}</ListItemText>
        </MenuItem>
    );
});

const AboutMenuItem = forwardRef(({ onClick, ...rest }, ref) => {
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        onClick && onClick()
        setOpen(false)
    }
    return (
        <>
            <MenuItem ref={ref} onClick={handleOpen}>
                <ListItemIcon>
                    <HelpIcon titleAccess={'Hỗ trợ'} />
                </ListItemIcon>
                {'Hỗ trợ'}
            </MenuItem>
            <AboutDialog onClose={handleClose} open={open} />
        </>
    )
})




function CustomAppBar(props) {
    const isLargeEnough = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const {permissions} = usePermissions();
    const CustomUserMenu = () => (
        <UserMenu>
            {permissions === 'admin' &&
                <ConfigurationMenu />
            }
            {permissions === 'admin' &&
                <AboutMenuItem />
            }
            <Logout />
        </UserMenu>
    );
    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<CustomUserMenu />}
        >
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            {/*{isLargeEnough && <Logo />}*/}
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
        </AppBar>
    );
}

export default CustomAppBar;
