import React from 'react';
import {Create, NumberInput, SimpleForm, TextInput} from "react-admin";
import {Grid} from "@mui/material";

const getCurrentMonth = () => {
    const currentDate = new Date();
    return currentDate.getMonth() + 1; // Tháng trong JavaScript bắt đầu từ 0, nên cộng 1 để lấy tháng hiện tại.
};
const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear(); // Năm trong JavaScript bắt đầu từ 0, nên cộng 1 để lấy năm hiện tại.
}
function ExpenseItemCreate(props) {
    return (
        <Create
            redirect="show"
        >
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NumberInput source="gameId" />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput label={"Số tiền"} source="amount" />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NumberInput defaultValue={getCurrentMonth()} source="month" />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput defaultValue={getCurrentYear()} source="year" />
                    </Grid>
                </Grid>
                <TextInput fullWidth source="note" />
            </SimpleForm>
        </Create>
    );
}

export default ExpenseItemCreate;
