import React from 'react';
import {DateInput, Edit, NumberInput, SimpleForm, TextInput, useRedirect, useRefresh} from 'react-admin';
import {Grid} from "@mui/material";
function HistoryexerciseEdit(props) {
    // const redirect = useRedirect();
    // const refresh = useRefresh();
    // const onSuccess = () => {
    //     redirect('/Exercises');
    //     refresh();
    // };
    return (
        <Edit redirect="show">
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <NumberInput source="day" label="Ngày" />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput source="month" label="Tháng" />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput source="year" label="Năm" />
                    </Grid>
                </Grid>
                <TextInput label={"Tên Set"} source="name" />
                <TextInput label={"Khối lượng x Reps"} source="result" />
                <TextInput label={"Ghi chú"} source="note" />
            </SimpleForm>
        </Edit>
    );
}

export default HistoryexerciseEdit;
