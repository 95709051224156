import React, {useEffect, useState} from 'react';
import {RecordContextProvider, ReferenceField, TextField, useDataProvider, useNotify, WrapperField} from "react-admin";
import {Box, Card, CardContent, CardHeader, Grid} from "@mui/material";

function GeneralResult(props) {
    const {istarget} = props;
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [infoContent, setInfoContent] = useState();
    useEffect(() => {
        const refreshRecord = () => {
            setLoading(true);
            dataProvider
                .getList(`Exercises/GetExercisesForDay`, {
                    pagination: {page: 1, perPage: 10},
                    sort: {field: 'id', order: 'DESC'},
                    filter: {target: istarget},
                })
                .then((resources) => {
                    setLoading(false);
                    setInfoContent(resources.data);
                })
                .catch((error) => {
                    setLoading(false);
                    notify(`Lỗi: ${error.body}`, {type: 'error'});
                });
        };
        refreshRecord();
    }, [dataProvider, notify]);
    return (
        (loading) ? <p>Loading...</p> :
            (
                infoContent?.map(record => (
                    <RecordContextProvider key={record.id} value={record}>
                        <Card
                            sx={{
                                margin: '0.5rem 0',
                            }}
                            variant="outlined"
                        >
                            <CardHeader
                                title={
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <WrapperField label="Ngày tập">
                                                <TextField style={{fontSize: '20px'}} source="day"/>-
                                                <TextField style={{fontSize: '20px'}} source="month"/>-
                                                <TextField style={{fontSize: '20px'}} source="year"/>
                                            </WrapperField>
                                        </Grid>
                                    </Grid>
                                }>
                            </CardHeader>
                            <CardContent sx={{pt: 0}}>
                                {record.listIdExercises.map(exercise => (
                                    <RecordContextProvider key={exercise.id} value={exercise}>
                                        <>
                                            {/*{idx++ + '. '}*/}
                                            <WrapperField label="Nhóm cơ">
                                                <TextField source="id"/>,&nbsp;
                                                <ReferenceField label="Bài tập" source="idx"
                                                                reference="Exercises"
                                                                link="show">
                                                    <TextField source="nameEL"/>
                                                </ReferenceField>
                                            </WrapperField>

                                            <Box mb={1}/>
                                        </>
                                    </RecordContextProvider>
                                ))}
                            </CardContent>
                        </Card>
                    </RecordContextProvider>

                ))
            )
    )
}

export default GeneralResult;
