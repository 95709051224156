import {setAuthHeader} from "./utils/axiosHeaders";
import axios from "axios";

export const userProfile = async () => {
    var token = localStorage.getItem("access_token");
    setAuthHeader(localStorage.getItem("access_token"));
    if (token && token !== "undefined") {
        axios.defaults.headers.common["Authorization"] = token ? "Bearer " + token : "";
        try {
            const response = await axios.get(`${process.env.REACT_APP_AUTH_SERVER_URL}/user/profile`);
            if (response.status === 200) {
                return response;
            }
        }
        catch (error) {
            return {
                status: error.response.status,
            };
        }
    } else {
        return {
            status: 403,
        };
    }
};
export const loadToken = () => {
    return localStorage.getItem("access_token");
}