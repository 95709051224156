import React from 'react';
import {
    BooleanField,
    DateField, ImageField,
    NumberField,
    RecordContextProvider,
    Show,
    SimpleShowLayout,
    TextField,
    WrapperField
} from 'react-admin';
import {Box, Card, CardContent, CardHeader, Grid} from "@mui/material";
function WeightgymShow(props) {
    return (
        <Show>
            <Card
                sx={{
                    margin: '0.5rem 0',
                    width: '100%'
                }}
                variant="outlined"
            >
                <CardHeader
                    title={
                        <Grid container alignItems="center">
                            <Grid item>
                                <WrapperField label="Ngày đo">
                                    <TextField style={{fontSize: '20px'}} source="day"/>-
                                    <TextField style={{fontSize: '20px'}} source="month"/>-
                                    <TextField style={{fontSize: '20px'}} source="year"/>
                                </WrapperField>
                            </Grid>
                        </Grid>
                    }
                    titleTypographyProps={{variant: 'body1'}}
                    action={
                        <></>
                    }
                />
                <CardContent sx={{pt: 0}}>
                    Cân nặng: <NumberField source="weight" /> kg
                    <br/>
                    Vòng eo: <NumberField source="waist" /> cm
                    <br/>
                    Chiều cao: <NumberField source="height" /> cm
                    <br/>
                    Có Coach: <BooleanField source="isCoach" />
                    <br/>
                    Ghi chú: <TextField source="note" />
                    <br/>
                    <ImageField source="image1" />
                    <ImageField source="image2" />
                    <ImageField source="image3" />
                </CardContent>
            </Card>
        </Show>
    );
}

export default WeightgymShow;
