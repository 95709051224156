import React from 'react';
import {useListContext} from "react-admin";
import LoadingGridList from "./LoadingGridList";
import LoadedGridList from "./LoadedGridList";

function GridList(props) {
    const {data,isLoading} = useListContext();
    return isLoading ? <LoadingGridList /> : <LoadedGridList />;
}

export default GridList;
