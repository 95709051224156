import React from 'react';
import {useRecordContext} from "react-admin";
import {Chip} from "@mui/material";
import {getTypeAccount} from "../../utils/CommonHelper";

function FieldTypeAccount(props) {
    const record =useRecordContext();
    if (!record) {
        return null;
    }
    return (
        <Chip
            size="small"
            key={record.type}
            label={getTypeAccount(record.type)}
            color={"primary"}
        />
    );
}

export default FieldTypeAccount;
