import React from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CardWithIcon from './CardWithIcon';
function TotalBill(props) {
    const { value } = props;
    return (
        <CardWithIcon
            to="/commands"
            icon={ReceiptIcon}
            title={'Tổng số đơn tháng này'}
            subtitle={value}
        />
    );
}

export default TotalBill;
