import React from 'react';
import {
    CreateButton,
    Datagrid,
    DateField,
    FilterButton,
    List,
    RecordContextProvider,
    ShowButton,
    TextField,
    TopToolbar,
    useListContext
} from 'react-admin';
import FieldActiveRule from "./FieldActiveRule";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery
} from "@mui/material";

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
    </TopToolbar>
);

function BauCuaRuleChannel() {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                Bạn chưa có bầu cua rule nào
            </Typography>
            <Typography variant="body1">
                Bạn hãy nhấn vào nút ở dưới để tạo mới.
            </Typography>
            <CreateButton
                variant="contained"
                sx={{mt: 2}}
                label="Thêm Rule Game"
            />
        </Box>
    )
}
const postFilters = [
];

const BauCuaRuleListMobile= () => {
    const {data} = useListContext();
    let idx = 1;
    return (
        <Box margin="0.5em">
            {
                data.map(record => (
                    <RecordContextProvider key={record.id} value={record}>
                        <Card
                            sx={{
                                margin: '0.5rem 0',
                            }}
                            variant="outlined"
                        >
                            <CardHeader
                                title={
                                    <>
                                        {idx++ + '. '}
                                        <TextField
                                            source="name"
                                            variant="body1"
                                        />
                                    </>
                                }
                                titleTypographyProps={{variant: 'body1'}}
                                action={<ShowButton/>}
                            />
                            <CardContent sx={{pt: 0}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label={'menu.about'} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Dạng luật: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TextField source="name" label={"Dạng luật"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Trạng Thái Luật Con: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <FieldActiveRule source="activeR" id={1} label={"Trạng Thái Luật Con"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Trạng Thái Luật Cái: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <FieldActiveRule source="activeA" id={2} label={"Trạng Thái Luật Cái"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Luật Con: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TextField source="descriptionR" label={"Luật Con"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Luật Cái: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TextField source="descriptionA" label={"Luật Cái"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Ngày Tạo: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <DateField source="create_at" />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </RecordContextProvider>
                ))
            }
        </Box>
    );
}

function BaucuaruleList(props) {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
        isXSmall ? (
            <List
                emptyWhileLoading
                {...props}
                filters={postFilters}
                actions={<ListActions/>}
                empty={<BauCuaRuleChannel/>}
            >
                <BauCuaRuleListMobile/>
            </List>
        ) : (
            <>
                <List
                    {...props}
                    filters={postFilters}
                    actions={<ListActions/>}
                    empty={<BauCuaRuleChannel/>}
                >
                    <Datagrid rowClick="show">
                        <TextField source="name" label={"Dạng luật"} />
                        <FieldActiveRule source="activeR" id={1} label={"Trạng Thái Luật Con"} />
                        <FieldActiveRule source="activeA" id={2} label={"Trạng Thái Luật Cái"} />
                        <TextField source="descriptionR" label={"Luật Con"} />
                        <TextField source="descriptionA" label={"Luật Cái"} />
                        <DateField source="create_at" />
                    </Datagrid>
                </List>
            </>
        )
    );
}

export default BaucuaruleList;
