import React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Drawer,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {Error} from "@mui/icons-material";
import {styled} from '@mui/material/styles';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {useGetIdentity} from "react-admin";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoiMatKhau from "./DoiMatKhau";
import {formatDate} from "../utils/CommonHelper";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

function Loading() {
    return (
        <Typography variant="body2" gutterBottom>
            {'Đang tải dữ liệu...'}
        </Typography>
    )
}

function Configuration(props) {
    const [state, setState] = React.useState(0);
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState(0);
    };
    const changeState = (state) => {
        setState(state);
    }
    const { identity, isLoading, error } = useGetIdentity();

    if (isLoading) return <Loading />;
    if (error) return <Error />;
    if (!identity) return null;

    return (
        <Card
            sx={{
                background: theme =>
                    theme.palette.mode === 'dark'
                        ? '#F0FFFF'
                        : `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,

                color: '#F0FFFF',
                padding: '20px',
                marginTop: 2,
                marginBottom: '1em',
            }}
        >
            <TableContainer component={Paper}>
                <Table aria-label={'menu.about'} size="small">
                    <TableBody>
                        <TableRow key={'tencuahang'}>
                            <TableCell align="right" component="th" scope="row">
                                {'Cửa hàng: '}
                            </TableCell>

                            <TableCell align="left">
                                {identity.fullName}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'diachi'}>
                            <TableCell align="right" component="th" scope="row">
                                {'Địa chỉ: '}
                            </TableCell>

                            <TableCell align="left">
                                {identity.diaChi}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'phone'}>
                            <TableCell align="right" component="th" scope="row">
                                {'Sđt: '}
                            </TableCell>

                            <TableCell align="left">
                                {identity.soDienThoai}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'TenNhanVien'}>
                            <TableCell align="right" component="th" scope="row">
                                {'Chủ tiệm: '}
                            </TableCell>

                            <TableCell align="left">
                                {identity.tenNhanVien}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'LoaiAccount'}>
                            <TableCell align="right" component="th" scope="row">
                                {'Gói: '}
                            </TableCell>

                            <TableCell align="left">
                                {identity.typeGoiCuoc===1 ? 'Cơ bản':'Nâng cao'}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'NgayGiaHan'}>
                            <TableCell align="right" component="th" scope="row">
                                {'Gia hạn: '}
                            </TableCell>

                            <TableCell align="left">
                                {formatDate(identity.ngayGiaHan)}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'NgayHetHan'}>
                            <TableCell align="right" component="th" scope="row">
                                {'Hết hạn: '}
                            </TableCell>

                            <TableCell align="left">
                                {formatDate(identity.ngayHetHan)}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'songayconlai'}>
                            <TableCell align="right" component="th" scope="row">
                                {'Đã dùng: '}
                            </TableCell>

                            <TableCell align="left">
                                {identity.soNgaySuDung-identity.soNgayConLai} ngày
                            </TableCell>
                        </TableRow>
                        <TableRow key={'songayclprogress'}>
                            <TableCell align="right" component="th" scope="row">
                                {'Còn lại: '}
                            </TableCell>
                            <TableCell align="left">
                                {identity.soNgayConLai} ngày
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <Box sx={{width: '100%', mr: 1}}>
                                        <BorderLinearProgress
                                            variant="determinate"
                                            value={(identity.soNgayConLai/identity.soNgaySuDung)*100}
                                        />
                                    </Box>
                                    <Box sx={{minWidth: 35}}>
                                        <Typography variant="body2" color="text.secondary">{`${Math.round(
                                            (identity.soNgayConLai/identity.soNgaySuDung)*100,
                                        )}%`}</Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box height={10}>&nbsp;</Box>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => changeState(1)}
                    >
                        {'Đổi mật khẩu'}
                    </Button>
                </Grid>
            </Grid>
            <Drawer
                open={state !== 0}
                onClose={handleClose}
            >
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <CardContent>
                        <Box display="flex">
                            <Box flex="1">
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        {state === 1 && <Typography variant="h6" gutterBottom>{'Đổi mật khẩu'}</Typography>}

                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            aria-label="close"
                                            onClick={handleClose}
                                            color={"error"}
                                            size="large"
                                        >
                                            <HighlightOffIcon fontSize="inherit"/>
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                {state === 1 && <DoiMatKhau/>}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Drawer>
        </Card>
    );
}

export default Configuration;

/*
<Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2">
                        {'Thông Tin'}
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p">
                            {'Tên cửa hàng: '} <b>{identity.fullName}</b>
                        </Typography>
                        <Typography variant="body1" component="p">
                            {'Địa chỉ: '} <b>{identity.diaChi}</b>
                        </Typography>
                        <Typography variant="body1" component="p">
                            {'Sđt: '} <b>{identity.soDienThoai}</b>
                        </Typography>
                        <Typography variant="body1" component="p">
                            {'Nhân viên: '} <b>{identity.tenNhanVien}</b>
                        </Typography>
                        <Typography variant="body1" component="p">
                            {'Loại tài khoản: '} <b>{identity.typeGoiCuoc===1 ? 'Cơ bản':'Nâng cao'}</b>
                        </Typography>
                        <Typography variant="body1" component="p">
                            {'Ngày hết hạn: '} <b>{dislayNgayHetHan}</b>
                        </Typography>
                    </Box>
                    <Box maxWidth="30em">

                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography variant="body1" component="p">
                                    {'Còn lại:'}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <BorderLinearProgress
                                    variant="determinate"
                                    value={(identity.soNgayConLai/identity.soNgaySuDung)*100}
                                    sx={{
                                        padding: '5px'
                                        // paddingTop: '5px',
                                }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" component="p" align="left">
                                    <b>{identity.soNgayConLai} {'ngày'}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box height={20}>&nbsp;</Box>
                    <Box maxWidth="60em">

                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => changeState(1)}
                                        >
                                        {'Đổi mật khẩu'}
                                    </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box
                    display={{ xs: 'none', sm: 'none', md: 'block' }}
                    sx={{
                        background: `url(${identity.avatar}) top right / cover`,
                        marginLeft: 'auto',
                    }}
                    width="25em"
                    height="14em"
                    overflow="hidden"
                />
            </Box>
 */