import React from 'react';
import {
    CreateButton,
    Datagrid,
    DateField,
    FilterButton,
    ImageField,
    List,
    NumberField,
    RecordContextProvider,
    ReferenceField,
    ShowButton,
    TextField,
    TopToolbar,
    useListContext
} from 'react-admin';
import TrangThaiGameField from "../TrangThaiGameField";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    Chip
} from "@mui/material";
import FieldOpenUrl from "../GameChannel/FieldOpenUrl";
import LinkToRelatedProducts from "./LinkToRelatedProducts";

function EmptyGameItem() {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                Bạn chưa có game nào
            </Typography>
            <Typography variant="body1">
                Bạn hãy nhấn vào nút ở dưới để tạo mới.
            </Typography>
            <CreateButton
                variant="contained"
                sx={{mt: 2}}
                label="Thêm Game"
            />
        </Box>
    )
}
const QuickFilter = ({ label }) => {
    return <Chip sx={{ marginBottom: 1 }} label={label} />;
};
const postFilters = [
];
const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
    </TopToolbar>
);

const GameItemListMobile= () => {
    const {data} = useListContext();
    let idx = 1;
    return (
        <Box margin="0.5em">
            {
                data.map(record => (
                    <RecordContextProvider key={record.id} value={record}>
                        <Card
                            sx={{
                                margin: '0.5rem 0',
                            }}
                            variant="outlined"
                        >
                            <CardHeader
                                title={
                                    <>
                                        {idx++ + '. '}
                                        <TextField
                                            source="name"
                                            variant="body1"
                                        />
                                    </>
                                }
                                titleTypographyProps={{variant: 'body1'}}
                                action={<ShowButton/>}
                            />
                            <CardContent sx={{pt: 0}}>
                                <TableContainer component={Paper}>
                                    <Table aria-label={'menu.about'} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Id: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="id"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Kênh: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <ReferenceField label="Kênh" source="channel_id"
                                                                    reference="gamechannel"
                                                                    link="show">
                                                        <TextField source="name"/>
                                                    </ReferenceField>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Trạng Thái: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TrangThaiGameField source="status" label="Trạng Thái"/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Đang Chơi: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="playerCount" label={"Số Máy"}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Đã Bật: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <NumberField source="deviceActive" label={"Đã Bật"}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'Ảnh Game: '}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <ImageField
                                                        sx={{'& img': {maxWidth: 60, maxHeight: 60, objectFit: 'contain'}}}
                                                        source="icon"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right" component="th" scope="row">
                                                    {'CH Play: '}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <FieldOpenUrl/>
                                                    <LinkToRelatedProducts/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </RecordContextProvider>
                ))
            }
        </Box>
    );
}

function GameitemList(props) {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            filters={postFilters}
            actions={<ListActions/>}
            empty={<EmptyGameItem/>}
        >
            <Datagrid rowClick="show">
                <NumberField source="id"/>
                <ImageField
                    sx={{'& img': {maxWidth: 60, maxHeight: 60, objectFit: 'contain'}}}
                    source="icon"
                />
                <NumberField source="playerCount" label={"Đang Chơi"}/>
                {!isXSmall && <ReferenceField label="Kênh" source="channel_id"
                                              reference="gamechannel"
                                              link="show">
                    <TextField source="name"/>
                </ReferenceField>
                }
                {!isXSmall && <TrangThaiGameField source="status" label="Trạng Thái"/>}
                {!isXSmall && <NumberField source="deviceActive" label={"Mã Đã Bật"}/>}
                {!isXSmall && <NumberField source="idUI" label={"Id UI"}/>}
                {!isXSmall && <NumberField source="codeCheckActive" label={"Code active"}/>}
                {!isXSmall && <FieldOpenUrl/>}
                {!isXSmall && <LinkToRelatedProducts/>}
            </Datagrid>
        </List>
        /*
        isXSmall ? (
            <List
                emptyWhileLoading
                {...props}
                filters={postFilters}
                actions={<ListActions/>}
                empty={<EmptyGameItem/>}
            >
                <GameItemListMobile/>
            </List>
        ) : (
            <>
                <List
                    {...props}
                    filters={postFilters}
                    actions={<ListActions/>}
                    empty={<EmptyGameItem/>}
                >
                    <Datagrid rowClick="show">
                        <NumberField source="id"/>
                        <ReferenceField label="Kênh" source="channel_id"
                                        reference="gamechannel"
                                        link="show">
                            <TextField source="name"/>
                        </ReferenceField>
                        <TrangThaiGameField source="status" label="Trạng Thái"/>
                        <ImageField
                            sx={{'& img': {maxWidth: 60, maxHeight: 60, objectFit: 'contain'}}}
                            source="icon"
                        />
                        <NumberField source="playerCount" label={"Đang Chơi"}/>
                        <NumberField source="deviceActive" label={"Mã Đã Bật"}/>
                        <FieldOpenUrl/>
                        <LinkToRelatedProducts/>
                    </Datagrid>
                </List>
            </>
        )
        */
    );
}

export default GameitemList;
