import React from 'react';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CardWithIcon from "./CardWithIcon";
function NoXau(props) {
    const { value } = props;
    return (
        <CardWithIcon
            to="/commands"
            icon={MoneyOffIcon}
            title={'Nợ cần đòi'}
            subtitle={value}
        />
    );
}

export default NoXau;
