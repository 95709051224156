import React from 'react';
import { DateInput, Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';
function AccgeoEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="first_name" />
                <TextInput source="last_name" />
                <TextInput source="email" />
                <TextInput source="phone" />
                <TextInput source="password" />
                <TextInput source="api_key" />
                <NumberInput source="is_active" />
                <NumberInput source="total_request" />
                <NumberInput source="moneyRemain" />
            </SimpleForm>
        </Edit>
    );
}

export default AccgeoEdit;
