import React from 'react';
import { SelectInput, Edit, NumberInput, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
function GameimageEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <NumberInput source="gameId" />
                <SelectInput source="typeImage" choices={[
                    { id: 0, name: 'Mặc định' },
                    { id: 1, name: 'Ảnh Rule' },
                    { id: 2, name: 'Ảnh Avoid' },
                ]} required />
                <NumberInput source="idUI" label={"Id UI"} required/>
                <TextInput multiline fullWidth source="url" />
            </SimpleForm>
        </Edit>
    );
}

export default GameimageEdit;
