import React from 'react';
import {Checkbox, TableCell, TableRow, Typography, useMediaQuery} from "@mui/material";
import {
    Datagrid,
    DatagridBody,
    DateField,
    List,
    ListContextProvider,
    NumberField,
    RecordContextProvider,
    TextField,
    TopToolbar,
    useGetList,
    useList,
    useRedirect
} from "react-admin";
import GameItemDangBatMobile from "./GameItemDangBatMobile";
import TypeRuleActive from "../../DeviceActiveRule/TypeRuleActive";
import FieldDeviceActiveStatus from "../../DeviceActiveRule/FieldDeviceActiveStatus";

const GameItemDangBatDataGridRow = ({record, id, onToggleItem, children, selected, selectable, rowClick}) => (
    <RecordContextProvider value={record}>
        <TableRow
            // onClick={event => onToggleItemCustom(id, event)}
            onClick={event => rowClick(id, event)}
        >
            {/* first column: selection checkbox */}
            <TableCell padding="none">
                <Checkbox
                    disabled={selectable}
                    checked={selected}
                    onClick={event => onToggleItem(id, event)}
                />
            </TableCell>
            {/* data columns based on children */}
            {React.Children.map(children, field => (
                <TableCell key={`${id}-${field.props.source}`}>
                    {field}
                </TableCell>
            ))}
        </TableRow>
    </RecordContextProvider>
);
const GameItemDangBatDatagridBody = props => <DatagridBody {...props} row={<GameItemDangBatDataGridRow/>}/>;
const GameItemDangBatDatagrid = props => <Datagrid {...props} body={<GameItemDangBatDatagridBody/>}/>;

const ListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

function GameItemDangBat(props) {
    const {idgame} = props;
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const redirect = useRedirect();
    const {data, total, isLoading, error} = useGetList(
        'DeviceActiveRule',
        {
            pagination: {page: 1, perPage: 10},
            sort: {field: 'conLai', order: 'ASC'},
            filter: {gameid: idgame}
        }
    );
    const listContext = useList({data, isLoading});
    const handleClick = (id, event) => {
        redirect('/DeviceActiveRule/' + id + '/show');
    }
    if (isLoading) {
        return null;
    }
    if (error) {
        return null;
    }
    return (
        isXSmall ? (
            (data === null || data.length==0) ? (
                <Typography
                    variant="h4"
                    color="textSecondary"
                    component="p"
                    style={{ color: 'red' }}
                >
                    Không có dữ liệu
                </Typography>
                ) : (
                    <>
                        <List
                            emptyWhileLoading
                            actions={<ListActions/>}
                        >
                            <GameItemDangBatMobile
                                {...props}
                                data={data}
                                rowClick={handleClick}
                            />
                        </List>
                    </>
                )
        ) : (
            (data === null || data.length==0) ? (
                <Typography
                    variant="h4"
                    color="textSecondary"
                    component="p"
                    style={{ color: 'red' }}
                >
                    Không có dữ liệu
                </Typography>
            ) : (
                <ListContextProvider
                    value={listContext}
                    actions={<ListActions/>}
                >
                    <GameItemDangBatDatagrid
                        {...props}
                        rowClick={handleClick}
                    >
                        <TextField source="deviceId" label={"Mã máy"}/>
                        <NumberField source="minuteUse" label={"Thời gian sử dụng"}/>
                        <NumberField source="conLai" label={"Thời gian còn lại"}/>
                        <FieldDeviceActiveStatus source="status" label={"Trạng Thái"}/>
                        <DateField source="create_at" showTime label={"Bật Lúc"}/>
                    </GameItemDangBatDatagrid>
                </ListContextProvider>
            )
        ));
}

export default GameItemDangBat;
