import React from 'react';
import CardWithIcon from "./CardWithIcon";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

function TotalChiPhi(props) {
    const { value } = props;
    return (
        <CardWithIcon
            to="/commands"
            icon={CurrencyExchangeIcon}
            title={'Tổng chi phí'}
            subtitle={value}
        />
    );
}

export default TotalChiPhi;
