import {Create, DateInput, minLength, NumberInput, required, SimpleForm, TextInput} from "react-admin";
import {Grid} from "@mui/material";
import React from "react";

export const UserCreate = () => (
    <Create>
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        source="UserName"
                        validate={
                            [
                                minLength(6),
                                required()
                            ]}
                        label={"Username"}
                        defaultValue={"camdo"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        source="Password"
                        validate={required()}
                        label={"Password"}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        source="tenCuaHang"
                        validate={required()}
                        label={"Tên cửa hàng"}
                        defaultValue={"Cầm đồ"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        source="tenNhanVien"
                        validate={required()}
                        label={"Tên nhân viên"}
                        defaultValue={"Anh"}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <NumberInput
                        source="typeGoiCuoc"
                        validate={required()}
                        label={"Gói cước"}
                        defaultValue={1}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput
                        source="soNgaySuDung"
                        validate={required()}
                        label={"Số ngày sử dụng"}
                        defaultValue={30}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <DateInput
                        source="ngayGiaHan"
                        validate={required()}
                        label={"Ngày gia hạn"}
                        defaultValue={new Date()}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        source="soDienThoai"
                        validate={required()}
                        label={"Sđt"}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <TextInput
                source="diaChi"
                validate={required()}
                label={"Địa chỉ"}
                multiline
                fullWidth
                defaultValue={"Quận"}
            />
        </SimpleForm>
    </Create>
);