import React from 'react';
import { DateInput, Edit, SimpleForm, TextInput } from 'react-admin';
function DetailaddEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="id" />
                <TextInput source="deviceUnique" />
                <TextInput source="address" />
                <DateInput source="create_at" />
            </SimpleForm>
        </Edit>
    );
}

export default DetailaddEdit;
