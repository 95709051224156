import React from 'react';
import {AutocompleteInput, Edit, NumberInput, required, SimpleForm, TextInput} from 'react-admin';

function GamechannelEdit(props) {
    const choiseNCCC = [
        {id: 0, name: 'Chưa xác định'},
        {id: 1, name: 'Phan Đăng Phú '},
        {id: 2, name: 'Đinh Hương'}
    ];
    const choiseStatus = [
        {id: 0, name: 'Đã Chết'},
        {id: 1, name: 'Còn Sống'},
    ];
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="name" label={"Tên Kênh"} />
                <AutocompleteInput
                    source="status"
                    label="Status"
                    choices={choiseStatus}
                />
                <AutocompleteInput
                    source="type"
                    label="NCCC"
                    choices={choiseNCCC}
                    validate={required()}
                />
                <TextInput source="email" />
                <TextInput source="url" />
            </SimpleForm>
        </Edit>
    );
}

export default GamechannelEdit;
