import React from 'react';
import {useRecordContext} from "react-admin";
import {Typography} from "@mui/material";
import {getFormatTime} from "../../utils/CommonHelper";

function TimeCachDayField(props) {
    const record =useRecordContext();
    if (!record) {
        return null;
    }
    return (
        <span style={{
            color: 'green'
        }}>
            {
                getFormatTime(record?.timeCachDay)
            }
        </span>
    );
}

export default TimeCachDayField;
