import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    ImageField,
    List,
    NumberField,
    TextField,
    WrapperField
} from 'react-admin';

function WeightgymList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <WrapperField label="Ngày đo">
                    <TextField source="day" />/
                    <TextField source="month" />/
                    <TextField source="year" />
                </WrapperField>
                <NumberField source="weight" label={"Cân nặng"}/>
                <BooleanField source="isCoach" label={"Coach"}/>
                <TextField source="note" label={"Ghi chú"} />
            </Datagrid>
        </List>
    );
}

export default WeightgymList;
