import React, {useState} from 'react';
import {
    DateField,
    DateInput, DeleteButton,
    FormTab, ImageField,
    NumberField, ReferenceField, SaveButton,
    Show,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput, Toolbar, useDataProvider, useNotify
} from 'react-admin';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    useMediaQuery
} from "@mui/material";
import GameItemBatLuat from "../../Game/GameItem/GameItemAction/GameItemBatLuat";
import GameItemDangBat from "../../Game/GameItem/GameItemAction/GameItemDangBat";
import GameItemXemLuat from "../../Game/GameItem/GameItemAction/GameItemXemLuat";
import GameItemSuaLuat from "../../Game/GameItem/GameItemAction/GameItemSuaLuat";
import GameItemThemAnh from "../../Game/GameItem/GameItemAction/GameItemThemAnh";
import {useParams} from "react-router-dom";
import AddResultExercise from "./Action/AddResultExercise";
import AddTargetExercise from "./Action/AddTargetExercise";
import HistoryInExercisesShow from "./HistoryInExercisesShow";
import ExercisesTodayShow from "./ExercisesTodayShow";
import ExercisesInfoShow from "./ExercisesInfoShow";

function ExerciseShow(props) {
    const {id} = useParams();
    const dataProvider = useDataProvider();
    const [state, setState] = React.useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const notify = useNotify();
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState(0);
    };

    const changeState = (state) => {
        setState(state);
    }
    const callBackClose = () => {
        setState(0);
    }
    const PostEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton disabled/>
            <DeleteButton disabled/>
        </Toolbar>
    );
    return (
        <Show>
            <TabbedForm toolbar={<PostEditToolbar/>}>
                <FormTab label="Info">
                    <ExercisesInfoShow id={parseInt(id)}/>
                </FormTab>
                <FormTab label="Today">

                    {/*<SelectInput*/}
                    {/*    source="gender"*/}
                    {/*    label="Giới tính"*/}
                    {/*    choices={[*/}
                    {/*        {id: 'male', name: 'Nam'},*/}
                    {/*        {id: 'female', name: 'Nữ'},*/}
                    {/*        {id: 'other', name: 'Khác'},*/}
                    {/*    ]}*/}
                    {/*/>*/}
                </FormTab>
                <FormTab label="Target">
                    <HistoryInExercisesShow
                        idexercise={parseInt(id)}
                        target={1}
                    />
                </FormTab>
                <FormTab label="History">
                    <HistoryInExercisesShow
                        idexercise={parseInt(id)}
                        target={0}
                    />
                </FormTab>
                {/*<Toolbar>*/}
                {/*    <SaveButton/>*/}
                {/*</Toolbar>*/}
            </TabbedForm>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{marginTop: 2}}
                        fullWidth
                        onClick={() => {changeState(1)}}
                    >
                        {'Add Result'}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{marginTop: 2}}
                        fullWidth
                        onClick={() => {changeState(2)}}
                    >
                        {'Add Target'}
                    </Button>
                </Grid>
            </Grid>
            {/*<SimpleShowLayout>*/}
            {/*    <TextField source="id" />*/}
            {/*    <NumberField source="idNhomCo" />*/}
            {/*    <TextField source="nameEL" />*/}
            {/*    <TextField source="nameVN" />*/}
            {/*    <TextField source="description" />*/}
            {/*    <TextField source="note" />*/}
            {/*    <DateField source="icon" />*/}
            {/*    <DateField source="image1" />*/}
            {/*    <DateField source="image2" />*/}
            {/*    <DateField source="image3" />*/}
            {/*    <DateField source="create_at" />*/}
            {/*</SimpleShowLayout>*/}
            <Dialog
                open={state !== 0}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen={state === 2 && isXSmall || state === 4 && isXSmall}
            >
                <DialogTitle id="alert-dialog-title">
                    {state === 1 && 'Create Result Exercise'}
                    {state === 2 && 'Create Target Exercise'}
                    {state === 3 && 'Xem luật'}
                    {state === 4 && 'Sửa luật'}
                    {state === 5 && 'Thêm ảnh'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    </DialogContentText>
                    {state === 1 && <AddResultExercise
                        idexercise={parseInt(id)}
                        closeCallback={callBackClose}
                    />}
                    {state === 2 && <AddTargetExercise
                        idexercise={parseInt(id)}
                        closeCallback={callBackClose}
                    />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Đóng</Button>
                </DialogActions>
            </Dialog>
        </Show>
    );
}

export default ExerciseShow;
