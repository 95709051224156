import React from 'react';
import { Datagrid, DateField, List, NumberField, TextField } from 'react-admin';
function GeneralExpenseList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="note" />
                <NumberField source="amount" />
                {/*<TextField source="description" />*/}
                <DateField source="create_at" />
            </Datagrid>
        </List>
    );
}

export default GeneralExpenseList;
