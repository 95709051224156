import {userProfile} from "./oauth";

export default {
    // called when the user attempts to log in
    login: ({username, password}) => {
        let xhr = new XMLHttpRequest();
        xhr.open("POST", `${process.env.REACT_APP_AUTH_SERVER_URL}/connect/token`, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.send("grant_type=password&username=" + username + "&password=" + password + "&scope=openid%20profile%20email%20posts-api%20users-api&client_id=react.client&client_secret=secret");
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let response = JSON.parse(xhr.responseText);
                localStorage.setItem("access_token", response.access_token);
            }
        }
        return new Promise((resolve, reject) => {
            xhr.onload = async () => {
                var profile = await userProfile();
                if (profile.status === 200) {
                    localStorage.setItem("profile", JSON.stringify(profile.data));
                }
                resolve(xhr.response);
            };
            xhr.onerror = () => {
                reject("login failed");
                reject(xhr.statusText);
            };
        });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('profile');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('profile');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem("access_token");
            let profile = localStorage.getItem("profile");
            if (token && profile) {
                resolve();
            } else {
                reject();
            }
        });
        // return localStorage.getItem('access_token')
        //     ? Promise.resolve()
        //     : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        //return array of permissions
        return new Promise((resolve, reject) => {
            let profile = localStorage.getItem("profile");
            if (profile) {
                let permissions = JSON.parse(profile).roles;
                if(permissions.includes("admin")){
                    resolve("admin");
                }
                else if(permissions.includes("creator")){
                    resolve("creator");
                }
                resolve("member");
            } else {
                reject();
            }
        });
    },
    getIdentity: () => {
        return new Promise((resolve, reject) => {
            let profile = localStorage.getItem("profile");
            let jsonObject = JSON.parse(profile);
            if (profile) {
                resolve({
                    id: jsonObject.userInfo.id,
                    fullName: jsonObject.userInfo.tenCuaHang,
                    tenNhanVien: jsonObject.userInfo.tenNhanVien,
                    diaChi: jsonObject.userInfo.diaChi,
                    soDienThoai: jsonObject.userInfo.soDienThoai,
                    typeGoiCuoc: jsonObject.userInfo.typeGoiCuoc,
                    ngayGiaHan: jsonObject.userInfo.ngayGiaHan,
                    soNgaySuDung: jsonObject.userInfo.soNgaySuDung,
                    soNgayConLai: jsonObject.userInfo.soNgayConLai,
                    ngayHetHan: jsonObject.userInfo.ngayHetHan,
                    avatar:
                        jsonObject.userInfo.avatarUrl,
                });
            } else {
                reject();
            }
        });
    }
};
