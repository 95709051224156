import React from 'react';
import {Create, SimpleForm, TextInput} from "react-admin";
import {Grid} from "@mui/material";

function CustomerCreate(props) {
    return (
        <Create
            redirect="show"
        >
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="name" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="phone" />
                    </Grid>
                </Grid>
                <TextInput source="note" fullWidth />
            </SimpleForm>
        </Create>
    );
}

export default CustomerCreate;
