import React from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import {DateField, NumberField, RecordContextProvider, TextField} from "react-admin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TypeRuleActive from "../../DeviceActiveRule/TypeRuleActive";
import FieldDeviceActiveStatus from "../../DeviceActiveRule/FieldDeviceActiveStatus";

function GameItemDangBatMobile(props) {
    const {data,rowClick} = props;
    let idx = 1;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Card>
                <CardContent>
                    <Box margin="0.5em">
                        {
                            data.map(record => (
                                <RecordContextProvider key={record.id} value={record}>
                                    <Card
                                        sx={{
                                            margin: '0.5rem 0',
                                        }}
                                        variant="outlined"
                                    >
                                        <CardHeader
                                            title={
                                                <>
                                                    {idx++ + '. '}
                                                    <TextField
                                                        source="deviceId"
                                                        variant="body1"
                                                    />
                                                </>
                                            }
                                            titleTypographyProps={{variant: 'body1'}}
                                            action={
                                                <IconButton
                                                    aria-label="show"
                                                    onClick={() => {
                                                        rowClick(record.id);
                                                    }}
                                                    color={'primary'}
                                                >
                                                    <VisibilityIcon/>
                                                </IconButton>
                                            }
                                        />
                                        <CardContent sx={{pt: 0}}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label={'menu.about'} size="small">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="right" component="th" scope="row">
                                                                {'Thời gian sử dụng: '}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <NumberField source="minuteUse" label={"Thời gian sử dụng"} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" component="th" scope="row">
                                                                {'Thời gian còn lại: '}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <NumberField source="conLai" label={"Thời gian còn lại"} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" component="th" scope="row">
                                                                {'Trạng Thái: '}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <FieldDeviceActiveStatus source="status" label={"Trạng Thái"}/>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="right" component="th" scope="row">
                                                                {'Bật Lúc: '}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <DateField source="create_at" showTime label={"Tạo Lúc"} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                </RecordContextProvider>
                            ))
                        }
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default GameItemDangBatMobile;
