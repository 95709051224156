import React from 'react';
import {DateInput, Create, SimpleForm, TextInput, ImageField, NumberInput} from "react-admin";
function ExerciseCreate(props) {
    return (
        <Create>
            <SimpleForm>
                <TextInput fullWidth source="icon" />
                <NumberInput source="idNhomCo" />
                <TextInput label={"Tên tiếng anh"} source="nameEL" />
                <TextInput label={"Tên tiếng việt"} source="nameVN" />
                <TextInput fullWidth label={"Mô tả"} source="description" />
                <TextInput fullWidth label={"Ghi chú"} source="note" />
                <TextInput fullWidth source="image1" />
                <TextInput fullWidth source="image2" />
                <TextInput fullWidth source="image3" />
            </SimpleForm>
        </Create>
    );
}

export default ExerciseCreate;
