import React from 'react';
import {
    NumberInput,
    required,
    SimpleForm,
    TextField,
    TextInput,
    useDataProvider,
    useNotify,
    useRefresh
} from "react-admin";
import {Button, Grid} from "@mui/material";
function GameItemBatLuatToolbar() {
    return null;
}
const getCurrentDay = () => {
    const currentDate = new Date();
    return currentDate.getDate(); // Ngày trong JavaScript bắt đầu từ 0, nên cộng 1 để lấy ngày hiện tại.
};
const getCurrentMonth = () => {
    const currentDate = new Date();
    return currentDate.getMonth() + 1; // Tháng trong JavaScript bắt đầu từ 0, nên cộng 1 để lấy tháng hiện tại.
};
const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear(); // Năm trong JavaScript bắt đầu từ 0, nên cộng 1 để lấy năm hiện tại.
}
function AddTargetExercise(props) {
    const {idexercise, closeCallback} = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const postAddResult = (data) => {
        dataProvider
            .create('Exercises/AddTarget', {
                data: {
                    targetexer: data.targetexer,
                    noteexer: data.noteexer,
                    name: data.namereps,
                    day: data.day,
                    month: data.month,
                    year: data.year,
                    exercisesId: idexercise
                },
            })
            .then(() => {
                closeCallback();
                refresh();
                notify('Tạo kết quả tập thành công', {type: 'success'});
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    }
    return (
        <SimpleForm
            onSubmit={postAddResult}
            toolbar={<GameItemBatLuatToolbar/>}
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <NumberInput source="day" label="Ngày" />
                </Grid>
                <Grid item xs={4}>
                    <NumberInput source="month" label="Tháng" defaultValue={getCurrentMonth()}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberInput source="year" label="Năm" defaultValue={getCurrentYear()}/>
                </Grid>
            </Grid>
            <TextInput source="namereps" fullWidth label={"Tên Set"} validate={required()} defaultValue={"Set 1"}/>
            <TextInput source="targetexer" fullWidth label={"Khối lượng x Reps"} validate={required()} defaultValue={"20x12"}/>
            <TextInput source="noteexer" fullWidth label={"Ghi chú"}/>
            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                {'Thêm mục tiêu'}
            </Button>

        </SimpleForm>
    );
}

export default AddTargetExercise;
