import React from 'react';
import {Button, Grid} from "@mui/material";
import {required, SimpleForm, TextInput, useDataProvider, useNotify, useRecordContext, useRefresh} from "react-admin";

function UserEditProfileToolbar() {
    return null;
}

function UserEditProfile(props) {
    const {closeCallback} = props;
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const record = useRecordContext();
    const notify = useNotify();
    //const postTatToanHopDong = () => {
    const profile = (dataPut) => {
        const diff = {
            tenCuaHang: dataPut.tenCuaHang,
            tenNhanVien: dataPut.tenNhanVien,
            diaChi: dataPut.diaChi,
            soDienThoai: dataPut.soDienThoai,
            avatarUrl: dataPut.avatarUrl,
            idIdenUser: record.id,
        };
        dataProvider
            .update('user/admin/profile', {
                id: record.userInfo.id,
                data: diff,
            })
            .then(() => {
                closeCallback();
                refresh();
                notify('Cập nhật thành công',{ type: 'success' });
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    };
    if (!record) return null;

    return (
        <SimpleForm
            onSubmit={profile}
            toolbar={<UserEditProfileToolbar/>}
            // onChange={HasChangeValue}
        >
            <TextInput
                source="tenCuaHang"
                label="Tên cửa hàng"
                validate={required()}
                defaultValue={record.userInfo.tenCuaHang}
                fullWidth
            />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        source="tenNhanVien"
                        label="Tên nhân viên"
                        validate={required()}
                        defaultValue={record.userInfo.tenNhanVien}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        source="soDienThoai"
                        label="Sđt"
                        validate={required()}
                        defaultValue={record.userInfo.soDienThoai}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <TextInput
                source="diaChi"
                label="Địa chỉ"
                multiline
                fullWidth
                validate={required()}
                defaultValue={record.userInfo.diaChi}
            />
            <TextInput
                source="avatarUrl"
                label="Avatar url"
                multiline
                fullWidth
                defaultValue={record.userInfo.avatarUrl}
            />

            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                Cập nhật
            </Button>
        </SimpleForm>
    );
}

export default UserEditProfile;