import React from 'react';
import {AutocompleteInput, Create, required, SimpleForm, TextInput} from "react-admin";

function GamechannelCreate(props) {
    const choiseNCCC = [
        {id: 0, name: 'Chưa xác định'},
        {id: 1, name: 'Phan Đăng Phú '},
        {id: 2, name: 'Đinh Hương'}
    ];
    return (
        <Create
            redirect="show"
        >
            <SimpleForm>
                <TextInput source="name" label={"Tên Kênh"} validate={required()} />
                <TextInput source="email" />
                <AutocompleteInput
                    source="type"
                    label="NCCC"
                    choices={choiseNCCC}
                    validate={required()}
                />
                <TextInput source="url" validate={required()} />
            </SimpleForm>
        </Create>
    );
}

export default GamechannelCreate;
