import React from 'react';
import {useSidebarState} from "react-admin";
import {Box, Collapse, List, ListItemIcon, MenuItem, Tooltip, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";

function SubMenu(props) {
    const { handleToggle, isOpen, name, icon, children, dense } = props;
    const [sidebarIsOpen] = useSidebarState();
    const header = (
        <MenuItem dense={dense} onClick={handleToggle}>
            <ListItemIcon sx={{ minWidth: 5 }}>
                {isOpen ? <ExpandMore /> : icon}
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {name}
            </Typography>
        </MenuItem>
    );
    return (
        <Box>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={'More features'} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    sx={{
                        '& a': {
                            transition:
                                'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                            paddingLeft: sidebarIsOpen ? 4 : 2,
                        },
                    }}
                >
                    {children}
                </List>
            </Collapse>
        </Box>
    );
}

export default SubMenu;
