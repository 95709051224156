import React from 'react';
import {Button} from "@mui/material";
import CHPlay from "../../Icon/CHPlay";
import {useRecordContext} from "react-admin";

function FieldOpenUrl(props) {
    const record =useRecordContext();
    if (!record) {
        return null;
    }
    return (
        <Button
            variant="outlined"
            // href="https://github.com/pingponggamestudio/Docker/wiki/Backup-and-Init-volumes"
            href={record?.url}
            startIcon={<CHPlay/>}
            target="_blank"
            rel="noopener noreferrer"
            sx={{color: 'green'}}
            onClick={ (e) => { e.stopPropagation(); } }
            // sx={{marginTop: 2}}
        >
            CH
        </Button>
    );
}

export default FieldOpenUrl;
