import React from 'react';
import {DateField, EmailField, NumberField, Show, SimpleShowLayout, TextField} from 'react-admin';

function AccgeoShow(props) {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <TextField source="email" />
                <TextField source="phone" />
                <TextField source="password" />
                <TextField source="api_key" />
                <NumberField source="is_active" />
                <NumberField source="total_hour" />
                <NumberField source="total_request" />
                <NumberField source="moneyRemain" />
                <DateField source="enable_at" />
                <DateField source="disable_at" />
                <DateField source="create_at" />
            </SimpleShowLayout>
        </Show>
    );
}

export default AccgeoShow;
