import React, {createElement} from 'react';
import {Box, Card, Divider, Typography} from '@mui/material';

import cartouche from './cartouche.png';
import cartoucheDark from './cartoucheDark.png';

function CardWithIcon(props) {
    const { icon, title, subtitle, to, children } = props;
    return (
        <Card
            sx={{
                minHeight: 52,
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                minWidth: 268,
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            }}
        >
            <Box
                sx={{
                    overflow: 'inherit',
                    padding: '16px',
                    background: theme =>
                        `url(${
                            theme.palette.mode === 'dark'
                                ? cartoucheDark
                                : cartouche
                        }) no-repeat`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& .icon': {
                        color: theme =>
                            theme.palette.mode === 'dark'
                                ? 'inherit'
                                : '#dc2440',
                    },
                }}
            >
                <Box width="3em" className="icon">
                    {createElement(icon, { fontSize: 'large' })}
                </Box>
                <Box textAlign="right">
                    <Typography color="textSecondary">{title}</Typography>
                    <Typography variant="h5" component="h2">
                        {subtitle || ' '}
                    </Typography>
                </Box>
            </Box>
            {children && <Divider />}
            {children}
        </Card>
    );
}

export default CardWithIcon;