import React from 'react';
import {
    NumberField,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useDataProvider,
    useNotify, useRefresh
} from "react-admin";
import {Button} from "@mui/material";


function GameItemThemAnhToolbar() {
    return null;
}
function GameItemThemAnh(props) {
    const {idgame, closeCallback} = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const postThemAnh = (data) => {
        dataProvider
            .create('GameItem/ThemAnh', {
                data: {
                    urlImg: data.urlImg,
                    typeImage: data.typeImage,
                    idUI: data.idUI,
                    gameId: idgame
                },
            })
            .then(() => {
                closeCallback();
                refresh();
                notify('Thêm ảnh thành công', {type: 'success'});
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    }
    return (
        <SimpleForm
            onSubmit={postThemAnh}
            toolbar={<GameItemThemAnhToolbar/>}
        >
            <NumberInput fullWidth label={"Id UI"} source="idUI" validate={required()} />
            <SelectInput fullWidth source="typeImage" choices={[
                { id: 0, name: 'Mặc định' },
                { id: 1, name: 'Ảnh Rule' },
                { id: 2, name: 'Ảnh Avoid' },
            ]} validate={required()} />
            <TextInput fullWidth multiline source="urlImg" label={"Url"} validate={required()}/>
            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                {'Thêm ảnh'}
            </Button>

        </SimpleForm>
    );
}

export default GameItemThemAnh;
