import React from 'react';
import {Datagrid, DateField, List, NumberField, TextField} from 'react-admin';
import RolesField from "./RolesField";
import ActiveField from "./ActiveField";

function UserList(props) {
    return (
        <List>
            <Datagrid
                rowClick="show"
            >
                <TextField source="userName" />
                <TextField
                    source="userInfo.tenNhanVien"
                    label="Tên nhân viên"
                />
                <TextField
                    source="userInfo.tenCuaHang"
                    label="Tên cửa hàng"
                />
                <TextField
                    source="userInfo.diaChi"
                    label="Địa chỉ cửa hàng"
                />
                <TextField
                    source="userInfo.soDienThoai"
                    label="Sđt"
                />
                <DateField
                    source="userInfo.ngayGiaHan"
                    label="Ngày gia hạn"
                />
                <NumberField
                    source="userInfo.soNgaySuDung"
                    label="Số ngày"
                />
                <NumberField
                    source="userInfo.soNgayConLai"
                    label="Số ngày còn lại"
                />
                <DateField
                    source="userInfo.ngayHetHan"
                    label="Ngày hết hạn"
                />
                <NumberField
                    source="userInfo.typeGoiCuoc"
                    label="Gói cước"
                />
                <DateField
                    source="userInfo.create_at"
                    label="Ngày tạo"
                />
                <ActiveField
                    label="Status"
                    source="userInfo.active"
                />
                <RolesField
                    source="roles"
                    label="Role"
                />
            </Datagrid>
        </List>
    );
}

export default UserList;