import React from 'react';
import {AutocompleteInput, Create, NumberInput, required, SimpleForm, TextInput} from "react-admin";
import {Grid} from "@mui/material";

function AccgeoCreate(props) {
    return (
        <Create
            redirect="show"
        >
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="first_name" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="last_name" fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="email" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="password" fullWidth />
                    </Grid>
                </Grid>
                <TextInput source="api_key" fullWidth />
                <TextInput source="phone" fullWidth />
            </SimpleForm>
        </Create>
    );
}

export default AccgeoCreate;
