import React from 'react';
import {useRecordContext} from "react-admin";
import {Chip} from "@mui/material";

function TypeImageField(props) {
    const record = useRecordContext();
    if (!record) {
        return null;
    }
    return (
        <Chip
            size="small"
            key={record.typeImage}
            label={record.typeImage == 0 ? "Mặc định" : record.typeImage == 1 ? "Ảnh Rule" : "Ảnh Avoid"}
            // label={getTrangThaiHD(record.trangthai)}
            color={record.typeImage == 0 ? "success" : record.typeImage == 1 ? "warning" : "primary"}
        />
    );
}

export default TypeImageField;
