import React from 'react';

import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

function DetailaddShow(props) {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="deviceUnique" />
                <TextField source="address" />
                <DateField source="create_at" />
            </SimpleShowLayout>
        </Show>
    );
}

export default DetailaddShow;
