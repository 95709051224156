import React from 'react';
import { Datagrid, DateField, List, NumberField, TextField } from 'react-admin';
function ExpenseList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="month" />
                <TextField source="year" />
                <NumberField source="totalAmount" />
                <TextField source="note" />
                <DateField source="create_at" />
            </Datagrid>
        </List>
    );
}

export default ExpenseList;
