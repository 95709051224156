import React from 'react';
import {AutocompleteInput, Create, NumberInput, required, SimpleForm, TextInput} from "react-admin";
import {Grid} from "@mui/material";

function GamekhachCreate(props) {
    const choiseTypeGame = [
        {id: 0, name: 'Trống'},
        {id: 1, name: 'Bầu cua'},
        {id: 2, name: 'Tài xỉu'},
        {id: 3, name: 'Xóc dĩa'}
    ];
    return (
        <Create
            redirect="show"
        >
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="name" label={"Tên Game"} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <AutocompleteInput
                            source="typeGame"
                            label="Chọn Loại Game"
                            choices={choiseTypeGame}
                            validate={required()}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NumberInput source="idAdmin" label={"Chủ Game"} fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput source="status" label={"Trạng Thái"} fullWidth/>
                    </Grid>
                </Grid>
                <TextInput source="apiKey" fullWidth />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="authDomain"  fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="packageName" fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="username" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="password" fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>

                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>
                <TextInput source="icon" fullWidth multiline />
                <TextInput source="urlCHPlay" label={"Link Chplay"} fullWidth multiline />
                <TextInput source="urlRTFB" label={"URL Realtime firebase"}  fullWidth multiline/>
            </SimpleForm>
        </Create>
    );
}

export default GamekhachCreate;
