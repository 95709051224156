import React from 'react';
import {PasswordInput, required, SimpleForm, useCreate} from "react-admin";
import {redirect} from "react-router";
import {Button} from "@mui/material";

function DoiMatKhauToolbar() {
    return null;
}
const PasswordValidate = values => {
    const errors = {};
    if (!values.currentPassword) {
        errors.currentPassword = 'Vui lòng nhập mật khẩu hiện tại';
    }
    if (!values.newPassword) {
        errors.newPassword = 'Nhập mật khẩu mới';
    }
    if (!values.confirmPassword ) {
        errors.confirmPassword = 'Required' ;
    } else if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = 'Mật khẩu không khớp';
    }

    return errors;
};
function DoiMatKhau(props) {
    const [create] = useCreate();
    const postHopDong = (data) => {
        create('nguvkl', {data});
        redirect('/nguvkl');
    };
    return (
        <SimpleForm
            onSubmit={postHopDong}
            toolbar={<DoiMatKhauToolbar/>}
            validate={PasswordValidate}
        >
            <PasswordInput
                source="currentPassword"
                label="Mật khẩu hiện tại"
                validate={required()}
                fullWidth
            />
            <PasswordInput
                label="Mật khẩu mới"
                source="newPassword"
                validate={required()}
                fullWidth
            />
            <PasswordInput
                label="Xác nhận mật khẩu"
                source="confirmPassword"
                validate={required()}
                fullWidth
                />

            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                Đổi mật khẩu
            </Button>
        </SimpleForm>
    );
}

export default DoiMatKhau;