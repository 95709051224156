import React from 'react';
import {
    DateInput,
    NumberInput,
    required,
    SimpleForm,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRefresh
} from "react-admin";
import {Button, Grid} from "@mui/material";


function UserEditGiaHanToolbar() {
    return null;
}

function UserEditGiaHan(props) {
    const {closeCallback} = props;
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const profile = (dataPut) => {
        const diff = {
            ngayGiaHan: dataPut.ngayGiaHan,
            typeGoiCuoc: dataPut.typeGoiCuoc,
            soNgaySuDung: dataPut.soNgaySuDung,
            idIdenUser: record.id,
        };
        dataProvider
            .update('user/admin/giahan', {
                id: record.userInfo.id,
                data: diff,
            })
            .then(() => {
                closeCallback();
                refresh();
                notify('Cập nhật thành công',{ type: 'success' });
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    };
    if (!record) return null;

    return (
        <SimpleForm
            onSubmit={profile}
            toolbar={<UserEditGiaHanToolbar/>}
        >
            <DateInput
                source="ngayGiaHan"
                label={"Ngày gia hạn"}
                defaultValue={new Date(record.userInfo.ngayGiaHan)}
                fullWidth
                validate={required()}
            />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <NumberInput
                        source="typeGoiCuoc"
                        validate={required()}
                        label={"Gói cước"}
                        defaultValue={record.userInfo.typeGoiCuoc}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput
                        source="soNgaySuDung"
                        label={"Số ngày sử dụng"}
                        validate={required()}
                        fullWidth
                        defaultValue={record.userInfo.soNgaySuDung}
                    />
                </Grid>
            </Grid>

            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                Cập nhật
            </Button>
        </SimpleForm>
    );
}

export default UserEditGiaHan;