import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import MonthlyRevenue from "./MonthlyRevenue";
import TotalMoney from "./TotalMoney";
import NoXau from "./NoXau";
import {useDataProvider, useNotify, usePermissions} from "react-admin";
import {formatNumber} from "../utils/CommonHelper";
import TotalChiPhi from "./TotalChiPhi";
import TotalBill from "./TotalBill";

function Dashboard(props) {
    const {permissions} = usePermissions();
    const dataProvider = useDataProvider();
    const [infoDashboard, setInfoDashboard] = useState();
    const notify = useNotify();
    useEffect(() => {
        const refreshRecord = () => {
            dataProvider.getOne(`Dashboard/GetDashboard`,{id: 1})
                .then((resources) => {
                    setInfoDashboard(resources.data);
                })
                .catch((error) => {
                    notify(`Lỗi: ${error.body}`, {type: 'error'});
                });
        };
        refreshRecord();
    }, [dataProvider, notify]);

    return (

        <Box>
            {permissions === 'admin' &&
                (<Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                        <MonthlyRevenue value={formatNumber(infoDashboard?.doanhThuThangNay)} đ/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TotalBill value={infoDashboard?.soDonThangNay} đ/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TotalMoney value={formatNumber(infoDashboard?.totalDoanhThu) } đ/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TotalChiPhi value={formatNumber(infoDashboard?.totalChiPhi)} đ/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <NoXau value={formatNumber(infoDashboard?.noCanThuHoi)} đ/>
                    </Grid>
                </Grid>)
            }
            {permissions !== 'admin' &&
                (
                    //make text center in box with padding 20px top and bottom and 0px left and right color red
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 2,
                        bgcolor: 'success.main'
                    }}>
                        <Typography variant="h4" component="div" gutterBottom>
                            Chào mừng bạn đến với trình quản lý
                        </Typography>
                    </Box>
                )
            }
        </Box>
    );
}
//tôi cần thiết kế một component để hiện thị các thông tin trong infoDashboard

export default Dashboard;
