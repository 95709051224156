import PropTypes from "prop-types";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import ZaloIcon from "./ZaloIcon";
import FacebookIcon from "./FacebookIcon";
import PhoneIcon from "./PhoneIcon";
import SafariIcon from "./SafariIcon";
import CHPlay from "../Icon/CHPlay";

const AboutDialog = ({ open, onClose }) => {
    return (
        <Dialog
            onClose={onClose}
            onBackdropClick={onClose}
            aria-labelledby="about-dialog-title"
            open={open}
        >
            <DialogTitle id="about-dialog-title" onClose={onClose}>
                Navidrome Music Server
            </DialogTitle>
            <DialogContent dividers>
                <TableContainer component={Paper}>
                    <Table aria-label={'menu.about'} size="small">
                        <TableBody>
                            <TableRow key={'phone'}>
                                <TableCell align="right" component="th" scope="row">
                                    <PhoneIcon/>
                                </TableCell>

                                <TableCell align="left">
                                    <Link
                                        href={`tel:0963604883`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {'0963.604.883'}
                                    </Link>
                                </TableCell>
                            </TableRow>
                            {/*<TableRow key={'safari'}>*/}
                            {/*    <TableCell align="right" component="th" scope="row">*/}
                            {/*        <SafariIcon/>*/}
                            {/*    </TableCell>*/}

                            {/*    <TableCell align="left">*/}
                            {/*        <Link*/}
                            {/*            href={`https://ototot.org/`}*/}
                            {/*            target="_blank"*/}
                            {/*            rel="noopener noreferrer"*/}
                            {/*        >*/}
                            {/*            {'ototot.org'}*/}
                            {/*        </Link>*/}
                            {/*    </TableCell>*/}
                            {/*</TableRow>*/}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    )
}

AboutDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export { AboutDialog }
