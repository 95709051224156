import React from 'react';
import {DateInput, Edit, NumberInput, SimpleForm, TextInput} from 'react-admin';

function PlayerinfoEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="id" />
                <NumberInput source="gameId" />
                <TextInput source="deviceId" />
                <NumberInput source="lat" />
                <NumberInput source="lng" />
                <NumberInput source="soVan" />
                <DateInput source="lastUpdate" />
                <DateInput source="create_at" />
            </SimpleForm>
        </Edit>
    );
}

export default PlayerinfoEdit;
