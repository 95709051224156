import React from 'react';
import {Button, Grid} from "@mui/material";
import {
    AutocompleteInput,
    required,
    SimpleForm,
    TextInput,
    useDataProvider,
    useNotify,
    useRedirect,
    useRefresh
} from "react-admin";
import {useParams} from "react-router-dom";

function GameItemCreateToolbar() {
    return null
}

function GameItemCreateForm(props) {
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { id } = useParams();
    const redirect = useRedirect();
    const choiseTypeGame = [
        {id: 0, name: 'Trống'},
        {id: 1, name: 'Bầu cua'},
        {id: 2, name: 'Tài xỉu'},
        {id: 3, name: 'Xóc dĩa'}
    ];
    const createHopDong = (dataCreate) => {
        dataProvider.create('GameItem', {
            data: {
                channelId: parseInt(id),
                name: dataCreate.nameGame,
                type: dataCreate.typeGame,
                icon: dataCreate.iconGame,
                image1: dataCreate.imageGame1,
                image2: dataCreate.imageGame2,
                image3: dataCreate.imageGame3,
                image4: dataCreate.imageGame4,
                image5: dataCreate.imageGame5,
                url: dataCreate.urlGame,
            }
        })
            .then((res) => {
                refresh();
                redirect(`/GameItem/${res.data.id}/show`);
                notify('Tạo game thành công',{ type: 'success' });
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    }
    return (
        <SimpleForm
            onSubmit={createHopDong}
            toolbar={<GameItemCreateToolbar/>}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        source="nameGame"
                        label={"Tên game"}
                        validate={required()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AutocompleteInput
                        source="typeGame"
                        label="Chọn Loại Game"
                        choices={choiseTypeGame}
                        validate={required()}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        source="iconGame"
                        label={"Icon game"}
                        validate={required()}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        source="urlGame"
                        label={"URL game"}
                        validate={required()}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        source="imageGame1"
                        label={"image1"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        source="imageGame2"
                        label={"image2"}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        source="imageGame3"
                        label={"image3"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        source="imageGame4"
                        label={"image4"}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <TextInput
                source="imageGame5"
                label={"image5"}
                fullWidth
            />
            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                Tạo Game
            </Button>
        </SimpleForm>
    );
}

export default GameItemCreateForm;
