import React from 'react';
import {DateField, NumberField, Show, SimpleShowLayout, TextField, Title} from 'react-admin';
import {
    Box, Button,
    Card,
    CardContent,
    Paper,
    Table,
    TableBody, TableCell,
    TableContainer, TableRow,
    Typography
} from "@mui/material";

function CustomerShow(props) {

    return (
        <Show>
            <Card>
                <Title
                    title={'pos.configuration'}
                >
                    <Typography>
                        {'Thông tin'}
                    </Typography>
                </Title>
                <CardContent>
                    <Box display="flex">
                        <Box flex="1">
                            <TextField
                                source="name"
                                variant={"h5"}
                                component={"h2"}
                            />
                            <Box height={10}>&nbsp;</Box>
                            <TableContainer component={Paper}>
                                <Table aria-label={'menu.about'} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Id: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <TextField source="id"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Name: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="name"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Số đơn: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="numberOrder"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'SDT: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="phone"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Note: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="note"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Ngày Tạo: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DateField source="create_at"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Show>
    );
}

export default CustomerShow;
