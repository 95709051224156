import React from 'react';
import {DateField, NumberField, Show, TextField, Title} from 'react-admin';
import FieldActiveRule from "./FieldActiveRule";
import {
    Box,
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";

function BaucuaruleShow(props) {
    return (
        <Show>
            <Card>
                <Title
                    title={'pos.configuration'}
                >
                    <Typography>
                        {'Thông tin'}
                    </Typography>
                </Title>
                <CardContent>
                    <Box display="flex">
                        <Box flex="1">
                            <TableContainer component={Paper}>
                                <Table aria-label={'menu.about'} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Name: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="name" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Trạng Thái Luật Con: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <FieldActiveRule source="activeR" id={1} label={"Trạng Thái Luật Con"} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Trạng Thái Luật Cái: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <FieldActiveRule source="activeA" id={2} label={"Trạng Thái Luật Cái"} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Luật con: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="descriptionR" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Luật cái: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="descriptionA" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Dạng luật: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="typeR" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Kiểu hãm: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="typeA" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Tạo Lúc: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DateField source="create_at" />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Show>
    );
}

export default BaucuaruleShow;
