import React from 'react';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    FilterForm,
    FilterContext,
    InputProps,
    ListBase,
    NumberInput,
    Pagination,
    ReferenceInput,
    SearchInput,
    SelectInput,
    SortButton,
    Title,
    TopToolbar,
    useGetResourceLabel, List, Datagrid, TextField, NumberField, DateField, ReferenceField, ImageField,
} from 'react-admin';
import {Box, Chip, useMediaQuery, Theme} from '@mui/material';
import * as PropTypes from "prop-types";
import Aside from "./Aside";
import ImageList from './GridList';

const ListActions = ({ isSmall }) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        {isSmall && <FilterButton />}
        <SortButton fields={['reference', 'sales', 'stock']} />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

function ExerciseList(props) {
    const getResourceLabel = useGetResourceLabel();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
        isXSmall?(<ListBase perPage={100} sort={{ field: 'reference', order: 'ASC' }}>
            {/*<Title defaultTitle={getResourceLabel('Exercise', 2)} />*/}
            {/*<FilterContext.Provider value={productFilters}>*/}
            {/*    <ListActions isSmall={isSmall} />*/}
            {/*    {isSmall && (*/}
            {/*        <Box m={1}>*/}
            {/*            <FilterForm />*/}
            {/*        </Box>*/}
            {/*    )}*/}
            {/*</FilterContext.Provider>*/}
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                height="750px"
            >
                {/*<Aside />*/}
                <Box
                    width={isSmall ? 'auto' : 'calc(100% - 16em)'}
                    marginLeft={isSmall ? 0 : '16em'}
                    mt={isSmall ? '1em' : 0}
                    ml={isSmall ? 0 : '1em'}
                >
                    <ImageList />
                    {/*<Pagination rowsPerPageOptions={[12, 24, 48, 72]} />*/}
                </Box>
            </Box>
        </ListBase>):(
            <List>
                <Datagrid rowClick="show">
                    <TextField source="id" />
                    <ImageField source="icon" />
                    <ReferenceField label="Nhóm cơ" source="idNhomCo"
                                    reference="CategoriesGym"
                                    link="show">
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextField label={"Tên tiếng anh"} source="nameEL" />
                    <TextField label={"Tên tiếng việt"} source="nameVN" />
                    <TextField label={"Mô tả"} source="description" />
                    <TextField label={"Ghi chú"} source="note" />
                </Datagrid>
            </List>
        )

    );
}
export const productFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput
        source="category_id"
        reference="categories"
        sort={{ field: 'id', order: 'ASC' }}
    >
        <SelectInput source="name" />
    </ReferenceInput>,
    <NumberInput source="width_gte" />,
    <NumberInput source="width_lte" />,
    <NumberInput source="height_gte" />,
    <NumberInput source="height_lte" />,
];
export default ExerciseList;
