import React from 'react';
import {DateField, NumberField, Show, TextField} from 'react-admin';
import RolesField from "./RolesField";
import ActiveField from "./ActiveField";
import {
    Box,
    Button,
    Card,
    CardContent,
    Drawer,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UserEditProfile from "./UserAction/UserEditProfile";
import UserEditChangePasswordAdmin from "./UserAction/UserEditChangePasswordAdmin";
import UserEditGiaHan from "./UserAction/UserEditGiaHan";
import UserEditKhoaAccount from "./UserAction/UserEditKhoaAccount";

function UserShow(props) {
    const [state, setState] = React.useState(0);
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState(0);
    };
    const changeState = (state) => {
        setState(state);
    }
    const callBackClose = () => {
        setState(0);
    }
    return (
        <Show>
            <TableContainer component={Paper}>
                <Table aria-label={'menu.about'} size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Username: '}
                            </TableCell>

                            <TableCell align="left">
                                <TextField source="userName" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Tên nhân viên: '}
                            </TableCell>

                            <TableCell align="left">
                                <TextField source="userInfo.tenNhanVien"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Tên: '}
                            </TableCell>

                            <TableCell align="left">
                                <TextField source="userInfo.tenCuaHang"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Địa chỉ: '}
                            </TableCell>

                            <TableCell align="left">
                                <TextField source="userInfo.diaChi"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Sđt: '}
                            </TableCell>

                            <TableCell align="left">
                                <TextField source="userInfo.soDienThoai"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Ngày gia hạn: '}
                            </TableCell>

                            <TableCell align="left">
                                <DateField source="userInfo.ngayGiaHan"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Số ngày đăng ký: '}
                            </TableCell>

                            <TableCell align="left">
                                <NumberField source="userInfo.soNgaySuDung"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Số ngày còn lại: '}
                            </TableCell>

                            <TableCell align="left">
                                <NumberField source="userInfo.soNgayConLai"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Ngày hết hạn: '}
                            </TableCell>

                            <TableCell align="left">
                                <DateField source="userInfo.ngayHetHan"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Gói cước: '}
                            </TableCell>

                            <TableCell align="left">
                                <NumberField source="userInfo.typeGoiCuoc"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Ngày tạo: '}
                            </TableCell>

                            <TableCell align="left">
                                <DateField source="userInfo.create_at"/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Status: '}
                            </TableCell>

                            <TableCell align="left">
                                <ActiveField
                                    label="Status"
                                    source="userInfo.active"
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" component="th" scope="row">
                                {'Roles: '}
                            </TableCell>

                            <TableCell align="left">
                                <RolesField
                                    source="roles"
                                    label="Role"
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{marginTop: 2}}
                        fullWidth
                        onClick={() => changeState(1)}
                    >
                        {'Edit Profile'}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{marginTop: 2}}
                        fullWidth
                        onClick={() => changeState(2)}
                    >
                        {'Change Password'}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{marginTop: 2}}
                        fullWidth
                        onClick={() => changeState(3)}
                    >
                        {'Gia Hạn'}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{marginTop: 2}}
                        fullWidth
                        onClick={() => changeState(4)}
                    >
                        {'Khoá Tài Khoản'}
                    </Button>
                </Grid>
            </Grid>
            <Drawer
                open={state !== 0}
                onClose={handleClose}
            >
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <CardContent>
                        <Box display="flex">
                            <Box flex="1">
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        {state === 1 && <Typography variant="h6" gutterBottom>{'Edit Profile'}</Typography>}
                                        {state === 2 && <Typography variant="h6" gutterBottom>{'Change Password'}</Typography>}
                                        {state === 3 && <Typography variant="h6" gutterBottom>{'Gia Hạn'}</Typography>}
                                        {state === 4 && <Typography variant="h6" gutterBottom>{'Khoá Tài Khoản'}</Typography>}

                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            aria-label="close"
                                            onClick={handleClose}
                                            color={"error"}
                                            size="large"
                                        >
                                            <HighlightOffIcon fontSize="inherit"/>
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                {state === 1 && <UserEditProfile closeCallback={callBackClose}/>}
                                {state === 2 && <UserEditChangePasswordAdmin closeCallback={callBackClose}/>}
                                {state === 3 && <UserEditGiaHan closeCallback={callBackClose}/>}
                                {state === 4 && <UserEditKhoaAccount closeCallback={callBackClose}/>}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Drawer>
        </Show>
    );
}
export default UserShow;