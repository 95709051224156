import React, {useEffect, useState} from 'react';
import {
    DateField,
    DateInput, DeleteButton,
    FormTab, ImageField,
    NumberField, ReferenceField, SaveButton,
    Show,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput, Toolbar, useDataProvider, useNotify,
    RecordContextProvider
} from 'react-admin';
import {useParams} from "react-router-dom";
import {Card, CardActions, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import LinkToRelatedBaiTap from "../Category/LinkToRelatedBaiTap";

function ExercisesInfoShow(props) {
    const {id} = useParams();
    const [images, setImages] = useState([]);
    const [infoExercises, setInfoExercises] = useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    useEffect(() => {
        const refreshRecord = () => {
            dataProvider
                .getOne(`Exercises`, {
                    id: id,
                })
                .then((resources) => {
                    setInfoExercises(resources.data);
                    //infoGameItem?.descriptionR
                    const imageArray = [resources.data.image1, resources.data.image2, resources.data.image3, resources.data.image4, resources.data.image5,].filter(Boolean); // loại bỏ các giá trị rỗng
                    setImages(imageArray);
                })
                .catch((error) => {
                    notify(`Lỗi: ${error.body}`, {type: 'error'});
                });
        };
        refreshRecord();
    }, [id, dataProvider, notify]);
    return (
        <RecordContextProvider key={infoExercises?.id} value={infoExercises}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ImageField source="icon" label="icon"/>
                </Grid>
                <Grid item xs={6}>
                    <ImageField source="image1" label="icon"/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ImageField source="image2" label="icon"/>
                </Grid>
                <Grid item xs={6}>
                    <ImageField source="image3" label="icon"/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField source="nameEL" label="Name EL"/>
                </Grid>
                <Grid item xs={6}>
                    <TextField source="nameVN" label="Name VN"/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    - Nhóm cơ:
                </Grid>
                <Grid item xs={6}>
                    <ReferenceField label="Nhóm cơ" source="idNhomCo"
                                    reference="CategoriesGym"
                                    link="show">
                        <TextField source="name"/>
                    </ReferenceField>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    - Mô tả:
                </Grid>
                <Grid item xs={8}>
                    <TextField source="description"/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    - Chú ý:
                </Grid>
                <Grid item xs={8}>
                    <TextField source="note"/>
                </Grid>
            </Grid>
        </RecordContextProvider>
    );
}

export default ExercisesInfoShow;
