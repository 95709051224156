import { Datagrid, DateField, List, TextField } from 'react-admin';

function DetailaddList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="deviceUnique" />
                <TextField source="address" />
                <DateField showTime source="create_at" />
            </Datagrid>
        </List>
    );
}

export default DetailaddList;
