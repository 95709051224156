import React from 'react';
import {DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField, Title} from 'react-admin';
import {
    Box,
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";

function SalesItemShow(props) {
    return (
        <Show>
            <Card>
                <Title
                    title={'pos.configuration'}
                >
                    <Typography>
                        {'Thông tin'}
                    </Typography>
                </Title>
                <CardContent>
                    <Box display="flex">
                        <Box flex="1">
                            <TableContainer component={Paper}>
                                <Table aria-label={'menu.about'} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Id: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <TextField source="id"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Game: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <ReferenceField label="Game" source="gameId"
                                                                reference="GameItem"
                                                                link="show">
                                                    <TextField source="name"/>
                                                </ReferenceField>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Tháng: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="month"/>/
                                                <TextField source="year"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Doanh số: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="totalSales"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Số đơn: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="totalAmount"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Ghi chú: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="note"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Ngày tạo: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DateField source="create_at"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Show>
    );
}

export default SalesItemShow;
