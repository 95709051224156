import React from 'react';
import {DateInput, Edit, NumberInput, SimpleForm, TextInput} from 'react-admin';

function BaucuaruleEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="id" />
                <TextInput source="name" />
                <NumberInput source="activeR" />
                <NumberInput source="activeA" />
                <DateInput source="nai" />
                <NumberInput source="bau" />
                <NumberInput source="ga" />
                <NumberInput source="ca" />
                <NumberInput source="cua" />
                <NumberInput source="tom" />
                <DateInput source="cai1" />
                <NumberInput source="cai2" />
                <NumberInput source="cai3" />
                <NumberInput source="cai4" />
                <NumberInput source="cai5" />
                <NumberInput source="cai6" />
                <NumberInput source="n" />
                <NumberInput source="con" />
                <TextInput source="descriptionR" />
                <TextInput source="descriptionA" />
                <NumberInput source="typeR" />
                <NumberInput source="typeA" />
                <DateInput source="create_at" />
            </SimpleForm>
        </Edit>
    );
}

export default BaucuaruleEdit;
