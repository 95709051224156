import React from 'react';
import {Datagrid, DateField, ImageField, List, NumberField, ReferenceField, TextField, UrlField} from 'react-admin';
import TypeImageField from "./TypeImageField";

function GameimageList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <ImageField source="url" label={"Ảnh"}/>
                <ReferenceField label="Game" source="gameId"
                                reference="GameItem"
                                link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <TypeImageField label={"Kiểu Image"} source="typeImage" />
                <NumberField label={"Id UI"} source="idUI" />
                <DateField source="create_at" />
            </Datagrid>
        </List>
    );
}

export default GameimageList;
