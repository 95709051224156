import React from 'react';
import {Chip} from "@mui/material";
import {useRecordContext} from "react-admin";

function FieldDeviceActiveStatus(props) {
    const record =useRecordContext();
    if (!record) {
        return null;
    }
    return (
        <Chip
            size="small"
            key={record.status}
            label={record.status===1 ? "Đang Dùng" : "Đã Ngừng"}
            color={record.status===1 ? "primary" : "error"}
        />
    );
}

export default FieldDeviceActiveStatus;
