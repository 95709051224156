import React from 'react';
import { Datagrid, DateField, List, NumberField, ReferenceField, TextField } from 'react-admin';
function SalesItemList(props) {
    return (
        <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <ReferenceField label="Game" source="gameId"
                                reference="GameItem"
                                link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="month" />
                <TextField source="year" />
                <NumberField label={"Doanh số"} source="totalSales" />
                <NumberField label={"Số đơn"} source="totalAmount" />
                <TextField source="note" />
                <DateField source="create_at" />
            </Datagrid>
        </List>
    );
}

export default SalesItemList;
