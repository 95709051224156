import React, {useEffect, useState} from 'react';
import {useDataProvider, useNotify} from "react-admin";

function GeneralInfo(props) {
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const [infoContent, setInfoContent] = useState();
    const notify = useNotify();
    useEffect(() => {
        const refreshRecord = () => {
            setLoading(true);
            dataProvider
                .getOne(`WeightGym/GetGeneralInfo`, {
                    id: 1
                })
                .then((resources) => {
                    setLoading(false);
                    setInfoContent(resources.data);
                })
                .catch((error) => {
                    setLoading(false);
                    notify(`Lỗi: ${error.body}`, {type: 'error'});
                });
        };
        refreshRecord();
    }, [dataProvider, notify]);
    return (
        <div>
            {loading ? (
                <span>Loading...</span>
            ) : (
                <div>
                    <h2>Thông Tin Chung</h2>
                    <p>Số ngày đã tập: {infoContent?.totalDaysExercise}</p>
                    <p>Số ngày có PT: {infoContent?.totalDaysCoaching}</p>
                </div>
            )}
        </div>
    );
}

export default GeneralInfo;
