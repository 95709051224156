import React from 'react';
import {DateField, NumberField, ReferenceField, Show, TextField, Title} from 'react-admin';
import {
    Box, Button,
    Card,
    CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography, useMediaQuery
} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import FieldDeviceActiveStatus from "./FieldDeviceActiveStatus";
import TypeRuleActive from "./TypeRuleActive";
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import {styled} from "@mui/material/styles";
import {green} from "@mui/material/colors";
import ActionMoveToDoanhThu from "./Action/ActionMoveToDoanhThu";

const GreenButton = styled(Button)({
    background: green[500],
    color: 'white',
    borderRadius: 20,
    padding: '12px 24px',
    '&:hover': {
        background: green[700],
    },
});

function DeviceactiveruleShow(props) {
    const {id} = useParams();
    const [state, setState] = React.useState(0);
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const handleMoveClick = async () => {
        console.log('handle Move Click');
    };
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState(0);
    };
    const changeState = (state) => {
        setState(state);
    }
    const callBackClose = () => {
        setState(0);
    }
    return (
        <Show>
            <Card>
                <Title
                    title={'pos.configuration'}
                >
                    <Typography>
                        {'Thông tin'}
                    </Typography>
                </Title>
                <CardContent>
                    <Box display="flex">
                        <Box flex="1">
                            <TableContainer component={Paper}>
                                <Table aria-label={'menu.about'} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Id: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="id" label={"Id"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Game: '}
                                            </TableCell>

                                            <TableCell align="left">
                                                <ReferenceField label="Game" source="gameId"
                                                                reference="GameItem"
                                                                link="show">
                                                    <TextField source="name"/>
                                                </ReferenceField>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Mã máy: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField source="deviceId" label={"Mã máy"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Thời gian sử dụng: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="minuteUse" label={"Thời gian sử dụng"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Thời gian còn lại: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <NumberField source="conLai" label={"Thời gian còn lại"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Trạng Thái: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <FieldDeviceActiveStatus source="status" label={"Trạng Thái"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" component="th" scope="row">
                                                {'Tạo Lúc: '}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DateField source="create_at" showTime/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <GreenButton
                                variant="contained"
                                startIcon={<TransferWithinAStationIcon/>}
                                onClick={() => changeState(1)}
                            >
                                {'Chuyển sang mục donh thu'}
                            </GreenButton>
                            <Dialog
                                open={state !== 0}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullScreen={state === 2 && isXSmall || state === 4 && isXSmall}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {state === 1 && 'Chuyển sang mục thu hồi'}
                                    {state === 2 && 'Đang bật'}
                                    {state === 3 && 'Xem luật'}
                                    {state === 4 && 'Sửa luật'}
                                    {state === 5 && 'Các máy đang chơi'}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {state === 5 && 'Bạn có chắc chắn muốn thanh lý tài sản này để thu hồi tiền không?'}
                                        {state === 6 && 'Thanh toán các khoản tiền ở dưới để tất toán hợp đồng.'}
                                    </DialogContentText>
                                    {state === 1 && <ActionMoveToDoanhThu
                                        iditem={parseInt(id)}
                                        closeCallback={callBackClose}
                                    />}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Đóng</Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Show>
    );
}

export default DeviceactiveruleShow;
