import React from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Pagination,
    ReferenceManyField,
    DateInput,
    required,
    TabbedForm,
    TextField,
    TextInput,
    BooleanInput,
    NumberInput, FormTab, SelectInput, Toolbar, SaveButton, SimpleForm, ImageField
} from 'react-admin';

function ExerciseEdit(props) {
    return (
        // <Edit {...props}>
        //     <TabbedForm >
        //         <FormTab label="Thông tin cơ bản">
        //             <TextInput source="nameEL" label="Tên"/>
        //             <TextInput source="nameVN" label="Email"/>
        //         </FormTab>
        //         <FormTab label="Thông tin bổ sung">
        //             <DateInput source="description" label="Ngày sinh"/>
        //             {/*<SelectInput*/}
        //             {/*    source="gender"*/}
        //             {/*    label="Giới tính"*/}
        //             {/*    choices={[*/}
        //             {/*        {id: 'male', name: 'Nam'},*/}
        //             {/*        {id: 'female', name: 'Nữ'},*/}
        //             {/*        {id: 'other', name: 'Khác'},*/}
        //             {/*    ]}*/}
        //             {/*/>*/}
        //         </FormTab>
        //         {/*<Toolbar>*/}
        //         {/*    <SaveButton/>*/}
        //         {/*</Toolbar>*/}
        //     </TabbedForm>
        // </Edit>

        // <Edit>
        //     <TabbedForm>
        //         <TabbedForm.Tab
        //             label="image"
        //             sx={{maxWidth: '40em'}}
        //         >
        //             {/*<Poster />*/}
        //             <ImageField source="icon"/>
        //             <ImageField source="image1"/>
        //         </TabbedForm.Tab>
        //         <TabbedForm.Tab
        //             label="resources.products.tabs.details"
        //             path="details"
        //             sx={{maxWidth: '40em'}}
        //         >
        //             {/*<ProductEditDetails />*/}
        //         </TabbedForm.Tab>
        //         <TabbedForm.Tab
        //             label="description"
        //             path="description"
        //             sx={{maxWidth: '40em'}}
        //         >
        //             {/*<RichTextInput source="description" label="" validate={req} />*/}
        //         </TabbedForm.Tab>
        //         {/*<TabbedForm.Tab*/}
        //         {/*    label="reviews"*/}
        //         {/*    count={*/}
        //         {/*        <ReferenceManyCount*/}
        //         {/*            reference="reviews"*/}
        //         {/*            target="product_id"*/}
        //         {/*            sx={{ lineHeight: 'inherit' }}*/}
        //         {/*        />*/}
        //         {/*    }*/}
        //         {/*    path="reviews"*/}
        //         {/*>*/}
        //         {/*    <ReferenceManyField*/}
        //         {/*        reference="reviews"*/}
        //         {/*        target="product_id"*/}
        //         {/*        pagination={<Pagination />}*/}
        //         {/*    >*/}
        //         {/*        <Datagrid*/}
        //         {/*            sx={{*/}
        //         {/*                width: '100%',*/}
        //         {/*                '& .column-comment': {*/}
        //         {/*                    maxWidth: '20em',*/}
        //         {/*                    overflow: 'hidden',*/}
        //         {/*                    textOverflow: 'ellipsis',*/}
        //         {/*                    whiteSpace: 'nowrap',*/}
        //         {/*                },*/}
        //         {/*            }}*/}
        //         {/*        >*/}
        //         {/*            <DateField source="date" />*/}
        //         {/*            <CustomerReferenceField />*/}
        //         {/*            <StarRatingField />*/}
        //         {/*            <TextField source="comment" />*/}
        //         {/*            <TextField source="status" />*/}
        //         {/*            <EditButton />*/}
        //         {/*        </Datagrid>*/}
        //         {/*    </ReferenceManyField>*/}
        //         {/*</TabbedForm.Tab>*/}
        //     </TabbedForm>
        // </Edit>
        <Edit>
            <SimpleForm>
                <ImageField fullWidth source="icon"/>
                <NumberInput source="idNhomCo"/>
                <TextInput label={"Tên tiếng anh"} source="nameEL"/>
                <TextInput label={"Tên tiếng việt"} source="nameVN"/>
                <TextInput fullWidth label={"Mô tả"} source="description"/>
                <TextInput fullWidth label={"Ghi chú"} source="note"/>
                <TextInput fullWidth source="icon"/>
                <TextInput fullWidth source="image1"/>
                <TextInput fullWidth source="image2"/>
                <TextInput fullWidth source="image3"/>
            </SimpleForm>
        </Edit>
    );
}

export default ExerciseEdit;
