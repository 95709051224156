import React from 'react';
import {
    AutocompleteInput,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
    useDataProvider,
    useNotify,
    useRefresh
} from "react-admin";
import {Button} from "@mui/material";

function GameItemBatLuatToolbar() {
    return null;
}
function AddResultExercise(props) {
    const {idexercise, closeCallback} = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const postAddResult = (data) => {
        dataProvider
            .create('Exercises/AddResult', {
                data: {
                    noteexer: data.noteexer,
                    resultexer: data.resultexer,
                    exercisesId: idexercise
                },
            })
            .then(() => {
                closeCallback();
                refresh();
                notify('Tạo mục tiêu thành công', {type: 'success'});
            })
            .catch((error) => {
                notify(`Lỗi: ${error.body}`, {type: 'error'});
            })
    }
    return (
        <SimpleForm
            onSubmit={postAddResult}
            toolbar={<GameItemBatLuatToolbar/>}
        >
            <TextInput source="resultexer" fullWidth label={"Khối lượng x Reps"} validate={required()}/>
            <TextInput source="noteexer" fullWidth label={"Ghi chú"}/>
            {/*<AutocompleteInput source="noteexer" fullWidth label={"Ghi chú"} choices={[*/}
            {/*    { id: 0, name: 'Drop set' },*/}
            {/*    { id: 1, name: 'Supper set' },*/}
            {/*    { id: 2, name: 'Triset' },*/}
            {/*    { id: 3, name: 'Janset' },*/}
            {/*]} />*/}
            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >
                {'Thêm kết quả'}
            </Button>

        </SimpleForm>
    );
}

export default AddResultExercise;
