import React from 'react';
import { DateInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
function GeneralExpenseEdit(props) {
    return (
        <Edit>
            <SimpleForm>
                <NumberInput label={"Số tiền"} source="amount" />
                <TextInput multiline fullWidth source="note" />
                {/*<TextInput source="description" />*/}
            </SimpleForm>
        </Edit>
    );
}

export default GeneralExpenseEdit;
