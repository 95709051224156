import React from 'react';
import {Layout} from 'react-admin';
import CustomAppBar from "./CustomAppBar";
import CustomMenu from "./CustomMenu";

function CustomLayout(props) {
    return (
        <Layout {...props} appBar={CustomAppBar} menu={CustomMenu}/>
    );
}

export default CustomLayout;
